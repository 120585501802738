<template>
  <div id="settingBox" class="settingBox clearFix">
    <ul :class="['options', menuBarFixed]" v-show="tabType === 'form'">
      <template v-for="(item, key, index) in setOptions">
        <section :class="['block', index == 1 ? 'block-bto' : '']" :key="key + index">
          {{ key }}
        </section>
        <template v-for="i in item">
          <li
            :class="{
              item: true,
              active: i.isActive || (i.name && questionArr.some(qes => qes.name == i.name))
            }"
            :key="i.label"
            @click="addFiled(i)"
            v-if="!i.hide"
          >
            <!-- <icon :class="item.icon"></icon> -->
            <icon class="icon" :icon-class="i.icon"></icon>
            <span>{{ i.label }}</span>
          </li>
        </template>
      </template>
      <div class="disable_wrap" v-if="!signUpSwtichTwo"></div>
    </ul>
    <div class="rightView" v-show="tabType === 'form'">
      <!-- 表单编辑组件 -->
      <fieldSet
        ref="fieldSet"
        :baseInfo="baseInfo"
        :isBilingual="isBilingual"
        :languageType="languageType"
        :questionArr.sync="questionArr"
        :signUpSwtich="signUpSwtichTwo"
        :regionalOptions="regionalOptions"
        v-on="$listeners"
        @upCurOption="upCurOption"
      ></fieldSet>
      <!-- <div class="disable_wrap" v-if="!signUpSwtichTwo"></div> -->
    </div>
    <!-- 表单预览组件 -->
    <signUpForm
      :currentFormData="currentFormData"
      :isBilingual="isBilingual"
      v-on="$listeners"
      v-if="rightComponent == 'signUpForm'"
      @closeSignUp="closePreview"
    ></signUpForm>
    <!-- 分享 -->
    <shareDialog
      :baseInfo="baseInfo"
      @setBaseInfo="setBaseInfo"
      ref="share"
      :isBilingual="isBilingual"
      :signUpPageType="signUpPageType"
      :webinarOrSubjectId="webinarOrSubjectId"
    ></shareDialog>
    <!-- 设置 -->
    <!-- <themeSet :baseInfo="baseInfo" @setBaseInfo="setBaseInfo" ref="theme"></themeSet> -->
  </div>
</template>

<script>
  import fieldSet from './fieldSet';
  import signUpForm from './components/signUpForm.vue';
  import { getfiledJson } from './util';
  import { sessionOrLocal } from '@/utils/utils';
  import shareDialog from './shareDialog';
  // import themeSet from './themeSet';
  export default {
    components: {
      fieldSet,
      signUpForm,
      shareDialog
    },
    inject: {
      subject_verify_live: { value: 'subject_verify_live', default: false },
      typeGroup_main: { value: 'typeGroup_main', default: '' },
      curQaInfo_main: { value: 'curQaInfo_main', default: {} }
    },
    props: {
      // 活动ID 或者 专题ID，跟signUpPageType字段组合使用
      webinarOrSubjectId: {
        type: [Number, String],
        default: 0
      },
      // 报名表单类型：webinar--活动；subject--专题
      signUpPageType: {
        type: [Number, String],
        default: ''
      },
      tabType: {
        type: String,
        default: ''
      },
      signUpSwtich: {
        type: Boolean,
        default: false
      },
      currentFormData: {
        type: Object,
        default: () => {
          return null;
        }
      },
      baseInfo: {
        type: Object,
        default: () => {
          return null;
        }
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      }
    },
    data() {
      return {
        signUpSwtichTwo: false,
        regionalOptions: {
          1: true,
          2: true
        },
        radio: 3,
        userId: '',
        rightComponent: 'fieldSet',
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        setOptions: {
          基本信息: [
            { icon: 'saasicon_name', label: '姓名', name: 'name', hide: false },
            { icon: 'saasicon_gender', label: '性别', name: 'gender', type: 'radio', hide: false },
            { icon: 'saasicon_phone', label: '手机', name: 'phone', hide: false },
            { icon: 'saasicon_mail', label: '邮箱', name: 'email', hide: false },
            { icon: 'saasicon_regional', label: '地域', name: 'regional', hide: false },
            { icon: 'saasicon_company', label: '公司', name: 'company', hide: false },
            { icon: 'saasfill-node1', label: '行业', name: 'industry', hide: false },
            { icon: 'saasfill-industry', label: '职务', name: 'duty', hide: false },
            { icon: 'saasicon_position', label: '职位', name: 'position', hide: false },
            { icon: 'saasfill-industry', label: '职务', name: 'duty_old', hide: false }
          ],
          题目类型: [
            { icon: 'saasicon_radio', label: '单选题', type: 'radio', hide: false },
            { icon: 'saasicon_multi-select', label: '多选题', type: 'checkBox', hide: false },
            { icon: 'saasicon_question', label: '问答题', type: 'input', hide: false },
            { icon: 'saasicon_drop-down', label: '下拉题', type: 'select', hide: false },
            { icon: 'saasicon_Privacystatement', label: '隐私声明', name: 'privacy', hide: false }
          ]
        },
        questionArr: [],
        menuBarFixed: ''
      };
    },
    computed: {
      signUpSwtichDesc() {
        if (this.signUpSwtichTwo) {
          return '已开启，观看直播需要填写报名表单';
        } else {
          return '开启后，观看直播需要填写报名表单';
        }
      },
      ques() {
        return this.questionArr.map(item => {
          if (item) {
            return item;
          }
        });
      },
      subject_verify_live_child() {
        return (this.subject_verify_live && this.subject_verify_live()) || false;
      },
      typeGroup_child() {
        return (this.typeGroup_main && this.typeGroup_main()) || '';
      },
      curQaInfo_child() {
        return (this.curQaInfo_main && this.curQaInfo_main()) || {};
      },
      showNewField() {
        //使用通用字段
        if (this.baseInfo && this.baseInfo.is_general_field == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      questionArr: {
        deep: true,
        immediate: true,
        handler(newValue) {
          const sumObj = {};
          newValue.forEach(item => {
            sumObj[item.reqType] ? sumObj[item.reqType]++ : (sumObj[item.reqType] = 1);
          });
          this.setOptions['题目类型'][2].isActive = sumObj[1] >= 20;
          this.setOptions['题目类型'][0].isActive = sumObj[2] >= 20;
          this.setOptions['题目类型'][1].isActive = sumObj[3] >= 20;
          this.setOptions['题目类型'][3].isActive = sumObj[4] >= 20;
        }
      },
      subject_verify_live_child(val) {
        console.log(val, '---==>>>');
        if (val) {
          this.addFiled({ type: 'webinars' });
        } else {
        }
      },
      signUpSwtich(val) {
        this.signUpSwtichTwo = val;
      },
      typeGroup_child(val) {
        // console.log(val, '---==>>>', JSON.stringify(this.curQaInfo_child));
        // 需要变更类型
        if (
          (this.curQaInfo_child.system_type == 10 ||
            this.curQaInfo_child.system_type == 11 ||
            this.curQaInfo_child.system_type == 12) &&
          ['radio', 'select', 'checkBox'].includes(this.curQaInfo_child.type) &&
          this.curQaInfo_child.type != val
        ) {
          console.log('---需要变更类型');
          for (let index = 0; index < this.questionArr.length; index++) {
            if (this.questionArr[index].question_id == this.curQaInfo_child.question_id) {
              if (this.questionArr[index].type == 'select') {
                let arr = this.questionArr[index].nodes.map(e => {
                  return {
                    item_id: e.item_id,
                    question_id: e.question_id,
                    type: e.type,
                    value: e.value,
                    general_option_key: e.general_option_key
                  };
                });
                this.questionArr[index].nodes = [
                  {
                    props: {},
                    value: [],
                    children: arr || []
                  }
                ];
              }
              if (
                this.questionArr[index].type == 'radio' ||
                this.questionArr[index].type == 'checkBox'
              ) {
                if (val == 'select') {
                  let arr = this.questionArr[index].nodes[0].children.map(e => {
                    return {
                      props: {
                        placeholder: '',
                        class: ['selectInput']
                      },
                      canRemove: true,
                      item_id: e.item_id,
                      question_id: e.question_id,
                      type: e.type,
                      value: e.value,
                      general_option_key: e.general_option_key
                    };
                  });
                  this.questionArr[index].nodes = arr;
                } else {
                }
              }
              this.questionArr[index].type = val;
              this.questionArr[index].reqType = { select: 4, checkBox: 3, radio: 2 }[val];
            }
          }
        }
      },
      showNewField: {
        deep: true,
        immediate: true,
        handler(val) {
          if (val) {
            this.setOptions['基本信息'][6].hide = false;
            this.setOptions['基本信息'][7].hide = false;
            this.setOptions['基本信息'][8].hide = false;
            this.setOptions['基本信息'][9].hide = true;
          } else {
            this.setOptions['基本信息'][6].hide = true;
            this.setOptions['基本信息'][7].hide = true;
            this.setOptions['基本信息'][8].hide = true;
            this.setOptions['基本信息'][9].hide = false;
          }
        }
      }
    },
    created() {
      this.signUpSwtichTwo = this.signUpSwtich;
    },
    mounted() {
      // if (this.signUpPageType === 'webinar') {
      document.getElementById('main_box').addEventListener('scroll', this.handleScroll);
      // }
    },
    beforeDestroy() {
      // if (this.signUpPageType === 'webinar') {
      document.getElementById('main_box').removeEventListener('scroll', this.handleScroll);
      // }
    },
    methods: {
      createWebinarsQuestion(lang) {
        if (!['zh', 'en'].includes(lang)) return;
        const qID = 'qID' + new Date().getTime() + Math.ceil(Math.random() * 1000);
        const filedJson = getfiledJson({ type: 'webinars', lang: lang });
        const ques = {
          ...filedJson,
          qID
        };
        if (lang === 'zh') {
          // 中文添加
          this.$parent.baseInfoOfZh?.ques_list.push(ques);
        } else if (lang === 'en') {
          // 英文添加
          this.$parent.baseInfoOfEn?.ques_list.push(ques);
        }
        return ques;
      },
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        params.language_type = this.languageType;
        return params;
      },
      // 切换开关的时候，修改展示字段状态
      setSwitchStatus(signUpSwtich) {
        this.signUpSwtichTwo = signUpSwtich;
      },
      // 更改表单基本信息的方法（通用）
      setBaseInfo(options, callback) {
        this.$fetch('regFromUpdate', {
          ...this.setParamsIdByRoute({}),
          is_independent_link: options.open_link
        })
          .then(res => {
            console.log(res);
            callback && callback();
          })
          .catch(err => {
            // 若宣发独立表单，且该专题下任意活动‘活动’已设置需要登录，若开启独立表单时提示‘该专题下活动已开启预约前强制登录，请关闭后重试’
            // 单个活动：若宣发独立表单，且该‘活动’已设置需要登录，若开启独立表单时提示‘该活动已开启预约前强制登录，请关闭后重试’
            if (err?.code === 510020) {
              this.$vhMessage({
                message: err?.msg,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              this.$refs.share?.updateShareSwitch(false);
            }
            console.log(err);
          });
      },
      initComp(list = []) {
        this.userId = JSON.parse(sessionOrLocal.get('userId'));
        this.questionArr = [];
        this.renderQuetionList(list);
      },
      handleScroll() {
        let scrollTop =
          document.getElementById('main_box').pageYOffset ||
          document.getElementById('main_box').scrollTop;
        let offsetTop = document.querySelector('#settingBox').offsetTop;
        // console.log(scrollTop, offsetTop);
        if (this.signUpPageType === 'webinar') {
          if (document.body.clientWidth > 1280) {
            if (scrollTop > offsetTop) {
              this.menuBarFixed = 'isFixed';
            } else {
              this.menuBarFixed = '';
            }
            this.$emit('changeTabsFixed', this.menuBarFixed);
            return false;
          }
        } else {
          if (document.body.clientWidth > 1280) {
            if (scrollTop > offsetTop) {
              this.menuBarFixed = 'isFixed';
            } else {
              this.menuBarFixed = '';
            }
            this.$emit('changeTabsFixed', this.menuBarFixed);
            return false;
          }
        }

        // // 对 1920*1080 屏幕缩放 150% 进行兼容
        // if (scrollTop > this.scrollTop && scrollTop > offsetTop) {
        //   // 向下滚
        //   this.menuBarFixed = 'isFixedBottom';
        // } else if (scrollTop < this.scrollTop && scrollTop > offsetTop) {
        //   // 向上滚
        //   this.menuBarFixed = 'isFixed';
        // } else {
        //   this.menuBarFixed = '';
        // }
        // this.$emit('changeTabsFixed', this.menuBarFixed);
        // this.scrollTop = scrollTop;
      },
      // 切换组件
      closePreview() {
        this.rightComponent = 'fieldSet';
      },
      renderQuetionList(list) {
        list.forEach(element => {
          // 如果是姓名、手机、邮箱、性别
          if (element.type === 0) {
            switch (element.default_type) {
              // 姓名
              case 1:
                this.addFiled(this.setOptions['基本信息'][0], element);
                break;
              // 手机
              case 2:
                this.addFiled(this.setOptions['基本信息'][2], element);
                break;
              // 邮箱
              case 3:
                this.addFiled(this.setOptions['基本信息'][3], element);
                break;
              // 性别
              case 4:
                this.addFiled(this.setOptions['基本信息'][1], element);
                break;
            }
          } else if (element.type === 1) {
            if (element.system_type == 9) {
              this.addFiled(this.setOptions['基本信息'][5], element);
              return false;
            }
            // 问答题
            this.addFiled(this.setOptions['题目类型'][2], element);
          } else if (element.type === 2) {
            // 单选
            if (element.system_type == 10) {
              //职位
              this.addFiled(this.setOptions['基本信息'][8], element);
              return false;
            }
            if (element.system_type == 12) {
              //职务
              this.addFiled(this.setOptions['基本信息'][7], element);
              return false;
            }
            if (element.system_type == 11) {
              //行业
              this.addFiled(this.setOptions['基本信息'][6], element);
              return false;
            }
            this.addFiled(this.setOptions['题目类型'][0], element);
          } else if (element.type === 3) {
            // 多选
            if (element.system_type == 10) {
              //职位
              this.addFiled(this.setOptions['基本信息'][8], element);
              return false;
            }
            if (element.system_type == 12) {
              //职务
              this.addFiled(this.setOptions['基本信息'][7], element);
              return false;
            }
            if (element.system_type == 11) {
              //行业
              this.addFiled(this.setOptions['基本信息'][6], element);
              return false;
            }
            // 多选
            this.addFiled(this.setOptions['题目类型'][1], element);
          } else if (element.type === 4) {
            if (element.system_type == 10) {
              //职位
              this.addFiled(this.setOptions['基本信息'][8], element);
              return false;
            }
            if (element.system_type == 12) {
              //职务
              this.addFiled(this.setOptions['基本信息'][7], element);
              return false;
            }
            if (element.system_type == 11) {
              //行业
              this.addFiled(this.setOptions['基本信息'][6], element);
              return false;
            }
            if (element.system_type == 8) {
              //老的职务
              this.addFiled(this.setOptions['基本信息'][9], element);
              return false;
            }
            // 下拉
            this.addFiled(this.setOptions['题目类型'][3], element);
          } else if (element.type === 5) {
            // 地域
            this.addFiled(this.setOptions['基本信息'][4], element);
          } else if (element.type === 6) {
            // 隐私
            this.addFiled(this.setOptions['题目类型'][4], element);
          } else if (element.type === 7) {
            // 活动选择设置
            this.addFiled({ type: 'webinars' }, element);
          }
        });
      },
      showSignUp() {
        this.rightComponent = 'signUpForm';
        this.$vhall_paas_port({
          k: 100188,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.webinar_id,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      // 添加题目方法（通用）
      addFiled(info, opts) {
        console.log('---addFiled:', info, opts, this.showNewField);
        let defaultQaInfo = null;
        if (
          (info.name == 'industry' || info.name == 'duty' || info.name == 'position') &&
          this.showNewField
        ) {
          const types = {
            industry: 11, //行业
            duty: 12, //职务
            position: 10 //职位
          };
          //使用通用字段 行业，职务职位
          let fieldInfo = this.baseInfo.general_field.find(e => {
            return e.system_type == types[info.name];
          });
          for (const item of fieldInfo.field_names) {
            if (item.lang_type == 0) fieldInfo.field_names_zh = item;
            if (item.lang_type == 1) fieldInfo.field_names_en = item;
          }
          for (const opt of fieldInfo.field_options) {
            for (const item of opt.option_names) {
              if (item.lang_type == 0) opt.option_names_zh = item;
              if (item.lang_type == 1) opt.option_names_en = item;
            }
          }
          defaultQaInfo = fieldInfo;
        }
        let mergeObj;
        let filedJson = getfiledJson({
          name: info.name,
          type: info.type || opts?.type,
          defaultQaInfo: defaultQaInfo,
          lang: this.languageType == 2 ? 'en' : 'zh'
        });
        // console.log('-=-=-=', filedJson);
        let qID = 'qID' + new Date().getTime() + Math.ceil(Math.random() * 1000);
        // 如果 opts 存在，为初始化赋值
        if (opts) {
          qID = opts.id;
          mergeObj = {
            question_id: opts.id,
            reqType: opts.type,
            default_type: opts.default_type,
            system_type: opts.system_type,
            label: opts.subject,
            order_num: opts.order_num,
            options: opts.options && JSON.parse(opts.options),
            required: !!opts.is_must,
            phoneValide: !!(
              opts.options &&
              JSON.parse(opts.options) &&
              JSON.parse(opts.options).open_verify
            ),
            emailValide: !!(
              opts.options &&
              JSON.parse(opts.options) &&
              JSON.parse(opts.options).open_verify
            ),
            // 支持国外手机号
            abroadPhoneValide: !!(
              opts.options &&
              JSON.parse(opts.options) &&
              JSON.parse(opts.options).support_foreign_phone
            )
          };
          filedJson = {
            ...filedJson,
            ...mergeObj
          };
          if (
            info.type === 'radio' ||
            info.type === 'checkBox' ||
            opts.type == 2 ||
            opts.type == 3
          ) {
            filedJson.nodes[0].children = [];
            opts.items.forEach((item, index) => {
              filedJson.nodes[0].children.push({
                item_id: item.id,
                value: item.subject,
                question_id: item.ques_id,
                placeholder: filedJson.placeholder || '',
                type: item.type,
                general_option_key: item.general_option_key,
                other: !!item.type
              });
            });
          } else if (info.type === 'select' || opts.type == 4) {
            filedJson.nodes = [];
            opts.items.forEach((item, index) => {
              filedJson.nodes.push({
                props: {
                  placeholder: filedJson.placeholder || '',
                  class: ['selectInput']
                },
                canRemove: true,
                item_id: item.id,
                value: item.subject,
                question_id: item.ques_id,
                type: item.type,
                general_option_key: item.general_option_key
              });
            });
          } else if (info.name === 'privacy' && opts.options && opts.options.length) {
            //隐私协议
            const optsOptions = JSON.parse(opts.options);
            const optsOptions0 = JSON.parse(optsOptions[0].options);
            filedJson.nodes[0].value = optsOptions0.content;
            filedJson.nodes[1].value = optsOptions0.color_text;
            filedJson.nodes[2].value = optsOptions0.url;
            for (let i = 1; i < optsOptions.length; i++) {
              let optsOptionsItem = JSON.parse(optsOptions[i].options);
              filedJson.nodes[2 * i + 1] = JSON.parse(JSON.stringify(filedJson.nodes[1]));
              filedJson.nodes[2 * i + 2] = JSON.parse(JSON.stringify(filedJson.nodes[2]));
              filedJson.nodes[2 * i + 1].value = optsOptionsItem.privacy_info;
              filedJson.nodes[2 * i + 1].privacy_id = optsOptionsItem.privacy_id;
              filedJson.nodes[2 * i + 2].value = optsOptionsItem.privacy_link;
              filedJson.nodes[2 * i + 2].privacy_id = optsOptionsItem.privacy_id;
              filedJson.nodes[2 * i + 1].privacyAdd = false;
              filedJson.nodes[2 * i + 1].canRemove = true;
            }
          } else if (opts.type === 0 || opts.type === 1) {
            filedJson.nodes[0].value = filedJson.options?.placeholder
              ? filedJson.options?.placeholder
              : filedJson.nodes[0].value;
          }
          if (info.type === 'checkBox') {
            filedJson.value = [];
          }
          // console.log('filedJson:', filedJson);
          this.questionArr.push(filedJson);
          // 地域选项状态
          if (filedJson.reqType === 5) {
            // 地域
            this.regionalOptions = {
              1: !!Number(filedJson.options.show_city),
              2: !!Number(filedJson.options.show_district)
            };
          }
          return false;
        } else {
          if (
            (info.type === 'radio' || info.type === 'checkBox') &&
            (filedJson.reqType == 2 || filedJson.reqType == 3)
          ) {
            for (let i = 0; i < filedJson.nodes[0].children.length; i++) {
              filedJson.nodes[0].children[i].question_id = qID;
              filedJson.nodes[0].children[i].item_id =
                'node_id_' + new Date().getTime() + Math.ceil(Math.random() * 1000);
            }
          }
          if (info.type === 'select' && filedJson.reqType == 4) {
            for (let index = 0; index < filedJson.nodes.length; index++) {
              filedJson.nodes[index].question_id = qID;
              filedJson.nodes[index].item_id =
                'node_id_' + new Date().getTime() + Math.ceil(Math.random() * 1000);
            }
          }
        }
        // console.log('-=-=-=222', filedJson);
        const questionsLength = this.questionArr.length;
        const lastQuestion = this.questionArr[questionsLength - 1];
        // console.log(questionsLength, lastQuestion);

        if (lastQuestion?.label === '隐私声明') {
          // 如果最后一个选项是隐私协议，新题目插到倒数第2位
          this.questionArr.splice(questionsLength - 1, 0, {
            ...filedJson,
            question_id: qID
          });
        } else {
          this.questionArr.push({
            ...filedJson,
            question_id: qID
          });
        }
        // console.log(this.questionArr, '---=-==');
        if (filedJson.reqType != 7) {
          this.$nextTick(() => {
            // 新增题目显示在视觉中心
            document
              .querySelector('#main_box')
              .scrollTo(0, this.$refs.fieldSet.$refs[qID][0].offsetTop - 70);

            const curQestInfo = this.questionArr.find(item => item.question_id == qID);
            this.$refs.fieldSet?.choseQa(curQestInfo);
          });
        }
        // console.log(filedJson);
      },
      // 添加一个隐私协议
      addPrivacy() {
        const options = {
          ...this.setParamsIdByRoute({}), // 活动ID 或者 专题ID
          content: '我们根据《隐私声明》保护您填写的所有信息',
          color_text: '《隐私声明》',
          url: ''
        };
        console.log(res);
        this.$fetch('regRrivacyAdd', options)
          .then(res => {
            // TODO: 给所有关于隐私协议的 node 都添加 privacy_id ,更新和删除的时候需要用到
            console.log(res);
          })
          .catch(err => console.log(err));
      },
      // 打开 dialog 方法（通用）
      openDialog(ref) {
        if (ref === 'share') {
          this.$vhall_paas_port({
            k: 100182,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.webinar_id,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.$refs[ref].dialogVisible = true;
      },
      // 选择选项之后更新 列表
      upCurOption({ checked, originOptions, curQaInfo }) {
        // console.log(this.questionArr, checked, originOptions, curQaInfo);
        for (let index = 0; index < this.questionArr.length; index++) {
          let e = this.questionArr[index];
          if (e.question_id == curQaInfo.question_id) {
            if (e.reqType == 4) {
              //下拉
              //更新node
              let nodes = JSON.parse(JSON.stringify(e.nodes));
              e.nodes = [];
              checked.map(e2 => {
                let existOption = null;
                nodes.map(e3 => {
                  if (e2.option_key == e3.general_option_key) {
                    existOption = e3;
                  }
                });
                if (existOption) {
                  e.nodes.push(existOption);
                } else {
                  e.nodes.push({
                    props: {
                      placeholder: '',
                      class: ['selectInput']
                    },
                    canRemove: true,
                    value: e2.option_name_zh,
                    item_id: 0,
                    question_id: e.question_id,
                    type: 0,
                    general_option_key: e2.option_key
                  });
                }
              });
              //更新options
              let options = [];
              originOptions.map(e2 => {
                e.options.general_field_options.map(e3 => {
                  if (e2.option_key == e3.option_key) {
                    options.push(e3);
                  }
                });
              });
              e.options.general_field_options = options;
            } else {
              //更新node
              let nodes = JSON.parse(JSON.stringify(e.nodes[0].children));
              e.nodes[0].children = [];
              checked.map(e2 => {
                let existOption = null;
                nodes.map(e3 => {
                  if (e2.option_key == e3.general_option_key) {
                    existOption = e3;
                  }
                });
                if (existOption) {
                  e.nodes[0].children.push(existOption);
                } else {
                  e.nodes[0].children.push({
                    props: {
                      placeholder: '',
                      class: []
                    },
                    canRemove: true,
                    value: e2.option_name_zh,
                    item_id: 0,
                    question_id: e.question_id,
                    type: 0,
                    general_option_key: e2.option_key
                  });
                }
              });
              //更新options
              let options = [];
              originOptions.map(e2 => {
                e.options.general_field_options.map(e3 => {
                  if (e2.option_key == e3.option_key) {
                    options.push(e3);
                  }
                });
              });
              e.options.general_field_options = options;
            }
          }
        }
      },
      phoneSwitchChange() {
        this.$nextTick(() => {
          this.$refs.fieldSet && this.$refs.fieldSet.phoneSwitchChange();
        });
      },
      emailSwitchChange() {
        this.$nextTick(() => {
          this.$refs.fieldSet && this.$refs.fieldSet.emailSwitchChange();
        });
      },
      initCurQaInfo() {
        this.$nextTick(() => {
          this.$refs.fieldSet && this.$refs.fieldSet.initCurQaInfo();
        });
      },
      updateInfo(info) {
        console.log('updateInfo--', info, this.questionArr);
        for (let index = 0; index < this.questionArr.length; index++) {
          if (this.questionArr[index].question_id == info?.question_id) {
            this.questionArr[index] = Object.assign(this.questionArr[index], info);
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .swtich {
    margin-left: 12px;
    vertical-align: sub;
  }
  .settingBox {
    position: relative;
    .isFixed {
      position: fixed !important;
      top: 104px;
      z-index: 999;
    }
    .isFixedBottom {
      position: fixed !important;
      z-index: 999;
      top: 115px;
      section {
        &.block {
          display: none;
        }
      }
    }
    .options {
      width: 144px;
      float: left;
      position: relative;
      padding-top: 12px;
      background: #ffffff;
      .block {
        font-size: 16px;
        color: #666666;
        height: 40px;
        line-height: 40px;
        padding-left: 21px;
      }
      .block-bto {
        margin-top: 10px;
      }
      .item {
        font-size: 14px;
        color: #1a1a1a;
        width: fit-content;
        height: 38px;
        cursor: pointer;
        padding-left: 21px;
        display: flex;
        align-items: center;

        &:hover {
          color: #fb3a32 !important;
          i {
            color: #fb3a32 !important;
          }
        }
        &.active {
          color: #b3b3b3;
          pointer-events: none;
          i {
            color: #b3b3b3;
          }
        }
        i {
          margin-right: 4px;
          color: #1a1a1a;
        }
        .icon {
          width: 14px;
          font-size: 14px;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
    .rightView {
      display: flex;
      justify-content: center;
      width: calc(100% - 144px);
      float: right;
      position: relative;
      background: #f7f7f7;
      padding-top: 0;
      .fieldSetBox {
        margin-left: 20px;
      }
    }
    .disable_wrap {
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
    .options .disable_wrap {
      background: #ffffff;
      opacity: 0.5;
    }
  }
</style>
