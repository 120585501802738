export default {
  exam: {
    1001: "不限时",
    1002: "单选",
    1003: "多选",
    1004: "上一题",
    1005: "下一题",
    1006: "提交",
    1007: "答对",
    1008: "答错",
    1009: "未答",
    1010: "查看答案",
    1011: "总分",
    1012: "分",
    1013: "正确率",
    1014: "答案",
    1015: "正确答案",
    1016: "您的答案",
    1017: "太棒了，全部答对了！",
    1018: "很遗憾，回答错误，继续努力！",
    1019: "正确",
    1020: "错误",
    1021: "满分",
    1022: "0分",
    1023: "关闭",
    1024: "网络繁忙，请重新作答。",
    1025: "重答",
    1026: "姓名",
    1027: "三方ID",
    1028: "参会ID",
    1029: "身份信息",
  },
};
