var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vmp-exam-item-comp" }, [
    _vm.item.status == 1
      ? _c("h1", { staticClass: "vmp-exam-item-title" }, [
          _c("span", { staticClass: "zdy-exam-question-type" }, [
            _vm._v(_vm._s(_vm.$t("exam.1002"))),
          ]),
          _vm._v(
            _vm._s(_vm.item.sortNum) + "." + _vm._s(_vm.item.title) + "\n    "
          ),
          _vm.item.score > 0
            ? _c("strong", [
                _vm._v(
                  "(" +
                    _vm._s(_vm.item.score) +
                    _vm._s(_vm.$t("exam.1012")) +
                    ")"
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.item.status == 2
      ? _c("h1", { staticClass: "vmp-exam-item-title" }, [
          _vm.item.required
            ? _c(
                "i",
                {
                  staticClass: "required",
                  attrs: { slot: "prefix" },
                  slot: "prefix",
                },
                [_vm._v("*")]
              )
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.item.title) + "：\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "exam-way__no__waterfallFlow" },
      [
        _vm.item.detail
          ? [
              _c(
                "vh-radio-group",
                {
                  on: { change: _vm.replys },
                  model: {
                    value: _vm.item.replys,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "replys", $$v)
                    },
                    expression: "item.replys",
                  },
                },
                _vm._l(_vm.item.detail.list, function (sonItem, sonIndex) {
                  return _c(
                    "div",
                    {
                      key: `qt_${_vm.index}_son${sonIndex}`,
                      class: [
                        "vmp-exam-info--option",
                        `question_${
                          _vm.item.extension.has_img ? "img-text" : "text"
                        }`,
                        `page-${_vm.answerType}`,
                        `${
                          _vm.item.extension.option_layout == 1
                            ? "max-width"
                            : "min-width"
                        }`,
                        `${
                          _vm.item.extension.img_layout == 1
                            ? "top-bottom"
                            : "left-right"
                        }`,
                        `bg_answer__${_vm.getStatus(sonItem)}`,
                        `${
                          _vm.item.replys.includes(sonItem.key) &&
                          _vm.answerType == 1
                            ? "active"
                            : ""
                        }`,
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.lineChecked(_vm.item, sonItem)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "vmp-exam-info--box",
                            `${
                              _vm.item.replys == sonItem.key
                                ? "text__min__width"
                                : "text__max__width"
                            }`,
                          ],
                        },
                        [
                          _vm.item.extension.has_img &&
                          sonItem.imgUrl != undefined
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vmp-exam-info--img object_fit_1",
                                },
                                [
                                  _vm.preview
                                    ? _c("vh-image", {
                                        attrs: {
                                          src: sonItem.imgUrl,
                                          fit: "scale-down",
                                          "z-index": 2020,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.preivew(
                                              sonIndex,
                                              _vm.srcList
                                            )
                                          },
                                        },
                                      })
                                    : _c("vh-image", {
                                        attrs: {
                                          src: sonItem.imgUrl,
                                          "preview-src-list": _vm.srcList,
                                          fit: "scale-down",
                                          "z-index": 2020,
                                        },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "vmp-exam-info--text",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "vh-radio",
                                {
                                  staticClass: "zdy-exam-radio",
                                  attrs: {
                                    label: sonItem.key,
                                    name: `radio_no_${_vm.item.id}`,
                                    disabled: _vm.answerType == 3,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: `item_${_vm.item.id}_option_${sonIndex}`,
                                      refInFor: true,
                                      class: [
                                        "text--content",
                                        `item_${_vm.item.id}_option_${sonIndex}`,
                                        "position_ellipsis",
                                      ],
                                    },
                                    [
                                      _c(
                                        "TextEllipsis",
                                        {
                                          staticClass: "introduce",
                                          attrs: {
                                            text:
                                              sonItem.key + "." + sonItem.value,
                                            textClass: "text-ellipsis",
                                            isLimitHeight: sonItem.ellipsisShow,
                                            height: sonItem.maxHeight,
                                          },
                                        },
                                        [
                                          sonItem.ellipsisShow
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ellipsis-toggle",
                                                  attrs: { slot: "more" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      _vm.$forceUpdate()
                                                      sonItem.ellipsisShow = false
                                                      _vm.$forceUpdate()
                                                    },
                                                  },
                                                  slot: "more",
                                                },
                                                [
                                                  _c("strong", [_vm._v("...")]),
                                                  _vm._v(
                                                    "展开\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !sonItem.ellipsisShow
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ellipsis-toggle link",
                                                  attrs: { slot: "after" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      _vm.$forceUpdate()
                                                      sonItem.ellipsisShow = true
                                                      _vm.$forceUpdate()
                                                    },
                                                  },
                                                  slot: "after",
                                                },
                                                [
                                                  _c("strong", [_vm._v(" ")]),
                                                  _vm._v("收起"),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.answerType == 3 &&
                                  ((_vm.item.replys == sonItem.key &&
                                    sonItem.is_answer == 0) ||
                                    sonItem.is_answer == 1)
                                    ? _c(
                                        "div",
                                        { staticClass: "text--icon--inline" },
                                        [
                                          _vm.item.replys == sonItem.key &&
                                          sonItem.is_answer == 0
                                            ? _c("img", {
                                                attrs: {
                                                  src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_close.png",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: sonItem.is_answer == 1,
                                                expression:
                                                  "sonItem.is_answer == 1",
                                              },
                                            ],
                                            attrs: {
                                              src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_yes.png",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.answerType == 3 &&
                      ((_vm.item.replys == sonItem.key &&
                        sonItem.is_answer == 0) ||
                        sonItem.is_answer == 1)
                        ? _c("div", { staticClass: "text--icon" }, [
                            _vm.item.replys == sonItem.key &&
                            sonItem.is_answer == 0
                              ? _c("img", {
                                  attrs: {
                                    src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_close.png",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: sonItem.is_answer == 1,
                                  expression: "sonItem.is_answer == 1",
                                },
                              ],
                              attrs: {
                                src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_exam_option_yes.png",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.answerType == 3
      ? _c("div", { staticClass: "vmp-exam-info--question--result" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("exam.1014")))]),
          _vm._v(" "),
          _c("div", { staticClass: "question--result--box" }, [
            _c("div", { staticClass: "question--result--left" }, [
              _c("p", [
                _c("strong", [_vm._v(_vm._s(_vm.$t("exam.1015")) + " ")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.correct_answer))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "question--result--center" }, [
              _c(
                "p",
                {
                  class:
                    _vm.item.replys == _vm.item.correct_answer
                      ? "answer_yes"
                      : "answer_no",
                },
                [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("exam.1016")) + " ")]),
                  _c("span", [_vm._v(_vm._s(_vm.item.replys))]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "question--result--right",
                  _vm.item.replys == _vm.item.correct_answer
                    ? "result-border-green"
                    : _vm.item.replys &&
                      _vm.item.replys != _vm.item.correct_answer
                    ? "result-border-red"
                    : "result-border-gray",
                ],
              },
              [
                _vm.item.replys && _vm.item.replys == _vm.item.correct_answer
                  ? _c("img", {
                      attrs: {
                        src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_correct.png",
                        alt: "",
                      },
                    })
                  : _vm.item.replys &&
                    _vm.item.replys != _vm.item.correct_answer
                  ? _c("img", {
                      attrs: {
                        src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_incorrect.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://s3.e.vhall.com/common-static/vhall-form/images/icon_no_answer.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class:
                      _vm.item.replys == _vm.item.correct_answer
                        ? "color-green"
                        : _vm.item.replys &&
                          _vm.item.replys != _vm.item.correct_answer
                        ? "color-red"
                        : "color-gray",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.item.replys == _vm.item.correct_answer
                            ? _vm.$t("exam.1019")
                            : _vm.item.replys &&
                              _vm.item.replys != _vm.item.correct_answer
                            ? _vm.$t("exam.1020")
                            : _vm.$t("exam.1009")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }