<template>
  <div class="prompt-editor">
    <!-- 内容区域 -->
    <div class="prompt-editor__bd">
      <div class="content-box" ref="editSmsCon">
        <!-- 编辑内容 -->
        <div
          ref="editableContent"
          class="content-input"
          contenteditable
          @keydown="handleKeydown"
          @keyup="handleKeyup"
          @focus="saveSelection"
          @click="saveSelection"
          @input="onContentChange"
          @paste="handlePaste"
        >
          <!-- 渲染文本和标签 -->
          <!-- prettier-ignore -->
          <template v-for="(item, index) in wordItems">
              <span
                v-if="['tag', 'fixer'].includes(item.type)"
                :class="[item.type]"
                :contenteditable="false"
                :data-value="item.value"
                :data-id="item.key"
              >{{ item.content }}</span>
              <template v-else>{{ item.content }}</template>
          </template>
        </div>

        <!-- 字数统计 -->
        <span class="content-count">
          <span class="content-count__current">{{ count }}</span>
          /{{ total }}
        </span>
      </div>
    </div>
    <!-- 底部 -->
    <div class="prompt-editor__ft">
      <!-- 通用下拉标签 -->
      <!-- <vh-dropdown class="menu-tags" @command="handleCommand" v-if="tagOptions.length > 0">
        <span class="vh-dropdown-link">
          插入标签
          <i class="vh-icon-arrow-down vh-icon--right"></i>
        </span>
        <vh-dropdown-menu slot="dropdown">
          <vh-dropdown-item :command="item" v-for="item in tagOptions" :key="item">
            {{ item }}
          </vh-dropdown-item>
        </vh-dropdown-menu>
      </vh-dropdown> -->
      <!-- 只有一个插入时间，根据产品要求定制化处理 -->
      <vh-button
        class="btn-time"
        type="text"
        :disabled="tagOptions.length < 1"
        @click="handleCommand('{start_time}')"
      >
        插入时间
      </vh-button>

      <vh-button class="btn-preview" type="default" @click="handlePreview" round size="mini">
        预览
      </vh-button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PromptEditor',
    data() {
      return {
        savedRange: null, // 用于存储光标位置的Range对象
        isUpdateTag: false,

        wordBaseKey: 100, // 分词基础key
        wordItems: [],
        count: 0,
        total: 100,
        tagOptions: []
      };
    },
    props: {
      // 待编辑的内容，例如：报名信息提交成功，直播将在{时间}正式开始，请准时参加
      content: String,

      // 编辑时的替换文本键值对
      replacementPair: {
        type: Object,
        default: () => ({})
      },

      // 预览时的替换值键值对
      previewPair: {
        type: Object,
        default: () => ({})
      }
    },
    watch: {
      content: {
        handler(newVal, oldValue) {
          if (!oldValue && newVal) {
            this.wordItems = this.splitString(newVal, Object.keys(this.replacementPair));
            this.$nextTick(() => {
              this.calcWordsCount();
              this.updateTagKeyOptions();
            });
          }
        },
        immediate: true
      }
    },
    methods: {
      splitString(oriStr, separators = []) {
        // 创建一个正则表达式，用于匹配上述所有分隔符
        // 使用 map 将分隔符转义，并在每个分隔符周围添加括号创建捕获组
        /* prettier-ignore */
        const regexPattern = separators.map(separator => { return `(${separator})`; }).join('|');
        const regex = new RegExp(regexPattern, 'g');
        // 使用split分割并保留分隔符
        let parts = oriStr.split(regex);
        // 剔除 undefined 和 空字符串项
        parts = parts.filter(x => x !== undefined && x !== '');
        const result = parts.map(item => {
          return {
            key: `word${++this.wordBaseKey}`,
            type: separators.includes(item) ? 'tag' : 'text',
            value: item,
            content: this.replacementPair[item] || item
          };
        });
        return result;
      },
      // 预览
      handlePreview() {
        let previewContent = this.getCurrentContent();
        Object.keys(this.previewPair).forEach(key => {
          previewContent = previewContent.replaceAll(key, this.previewPair[key]);
        });
        this.$vhAlert(`${previewContent}`, '预览', {
          showConfirmButton: false,
          dangerouslyUseHTMLString: false,
          customClass: 'prompt-editor-preview'
        })
          .then(() => {})
          .catch(() => {});
      },
      async handleKeydown(event) {
        if (event.key === 'Enter') {
          // 阻止回车键的默认行为（即不插入新行）
          event.preventDefault();
        } else if (event.key === 'Backspace') {
          let selection = window.getSelection();
          if (selection.rangeCount === 0) return; // 确保有活动选区

          await this.$nextTick();
          // let anchorNode = selection.anchorNode;
          // console.log('selection:', selection);
          // console.log('anchorNode:', anchorNode);

          const containerEle = this.$refs.editableContent;
          const range = selection.getRangeAt(0);
          // 创建一个包含范围内容的DocumentFragment
          const documentFragment = range.cloneContents();
          // console.log(`----> documentFragment:`, documentFragment);
          if (!documentFragment.hasChildNodes()) {
            // console.log(
            //   '%c> DocumentFragment 是空的，没有子节点，不使用DocumentFragment的内容',
            //   'color:red'
            // );
            // DocumentFragment没有内容，则使用range.endContainer
            let selectedNode = range.endContainer;
            let cursorPosition = range.endOffset;
            // console.log('selectedNode0:', selectedNode);
            // console.log('selectedNode0.childNodes.length:', selectedNode.childNodes.length);
            // console.log('selectedNode0 cursorPosition:', cursorPosition);

            let pendingNode;
            if (selectedNode.nodeType === 3 && cursorPosition === 0) {
              // 如果选中的是文本节点，且鼠标位置在文本最前面，则获取它的前一个节点
              pendingNode = selectedNode.previousSibling;
              // console.log('pendingNode1:', pendingNode);
            } else if (selectedNode.nodeType === 1 && cursorPosition > 0) {
              // 如果选中的是元素节点，且鼠标位置不在最前面,则获取它的最后一个子节点
              pendingNode = containerEle.childNodes[cursorPosition - 1];
              // console.log('pendingNode2:', pendingNode);
              // 如果最后一个子节点是空字符串
              if (pendingNode && pendingNode.nodeType === 3 && pendingNode.textContent == '') {
                // 取最后一个不为空的节点，解决光标在最后出现多个空文本节点的问题,多次才能删除的问题
                pendingNode = [...containerEle.childNodes]
                  .reverse()
                  .find(node => !(node.nodeType === 3 && node.textContent === ''));
                // console.log('pendingNode222:', pendingNode);
              }
            }
            if (pendingNode && pendingNode.nodeType === 3 && !pendingNode.textContent) {
              // console.log('----空字符串删除222---');
              this.$refs.editableContent.removeChild(pendingNode);
              pendingNode = null;
            }

            if (pendingNode && pendingNode.classList && pendingNode.classList.contains('fixer')) {
              // 不能删除
              event.preventDefault();
              event.stopPropagation();
            } else if (pendingNode && pendingNode.nodeType === 1) {
              // TODO:标记删除的是标签，需要更新标签,在keyup中处理
              // console.log('删除的是标签，需要更新标签');
              pendingNode.parentNode.removeChild(pendingNode);

              this.isUpdateTag = true;
              // 阻止默认行为
              event.preventDefault();
            } else {
              if (selectedNode.nodeType === 3 && cursorPosition > 0) {
                // console.log('文本节点,正常处理');
              } else if (!pendingNode || !pendingNode.textContent) {
                event.preventDefault();
              }
            }
          } else {
            // console.log(
            //   '%c> DocumentFragment  不是空的，有子节点，使用DocumentFragment的内容',
            //   'color:red'
            // );
            // 得倒着删除
            let len = documentFragment.childNodes.length;
            for (let i = len - 1; i >= 0; i--) {
              const childNode = documentFragment.childNodes[i];
              // 如果当前子节点不是我们要保留的节点，则移除它
              // 检查节点是否标记为保留
              const b = !!(
                childNode &&
                childNode.classList &&
                childNode.classList.contains('fixer')
              );
              // console.log('childNode.parentNode:', childNode.parentNode);
              if (!b) {
                // 删除节点
                childNode.parentNode.removeChild(childNode);
              }
              if (
                !this.isUpdateTag &&
                childNode &&
                childNode.classList &&
                childNode.classList.contains('tag')
              ) {
                console.log('this.isUpdateTag true');
                this.isUpdateTag = true;
              }
            }
            // 清除选区内容
            range.deleteContents();
            // 将剩余的内容插入文档
            range.insertNode(documentFragment);
            const lastChildNode = documentFragment.lastChild;
            if (lastChildNode) {
              range.setStart(lastChildNode, 0);
              range.setEnd(lastChildNode, 0);
            }
            // 阻止默认行为
            // event.preventDefault();
          }
        }
      },
      handleKeyup() {
        this.saveSelection();

        if (this.isUpdateTag) {
          this.calcWordsCount();
          this.updateTagKeyOptions();
        }
      },
      saveSelection() {
        const selection = window.getSelection();
        // 仅当selection中有range时保存
        if (selection.rangeCount > 0) {
          this.savedRange = selection.getRangeAt(0);
        }
      },
      onContentChange(event) {
        // 当内容改变时调用，event.target 会是触发事件的元素
        this.calcWordsCount();
        this.updateTagKeyOptions();

        this.$emit('updateContent', this.getCurrentContent());

        if (this.count >= this.total) {
          event.preventDefault();
        }
      },
      // 计算内容字数
      calcWordsCount() {
        let c = 0;
        let isLgLimit = false;
        const lgLimitChildNodes = []; //超字数的childNodes
        for (let i = 0; i < this.$refs.editableContent.childNodes.length; i++) {
          const childNode = this.$refs.editableContent.childNodes[i];
          if (isLgLimit) {
            if (childNode && childNode.nodeType === 3) {
              lgLimitChildNodes.push(childNode);
            }
          } else {
            if (childNode && childNode.nodeType === 3) {
              if (c + childNode.textContent.length >= this.total) {
                const txt = childNode.textContent.substring(0, this.total - c);
                childNode.textContent = txt;
                c = this.total;
                isLgLimit = true;

                // 恢复光标位置
                const range = document.createRange();
                const sel = window.getSelection();
                range.selectNodeContents(childNode);
                range.collapse(false); // false 表示将光标放在内容的尾部
                sel.removeAllRanges();
                sel.addRange(range);
                // break;
              } else {
                console.log('isLgLimit: c+ len:', c, '+', childNode.textContent.length);
                c = c + childNode.textContent.length;
              }
            }
          }
        }
        this.count = c;
        // 移除超字数的childNodes
        if (lgLimitChildNodes.length) {
          lgLimitChildNodes.forEach(child => {
            this.$refs.editableContent.removeChild(child);
          });
        }
      },
      // 粘贴
      handlePaste(event) {
        // 阻止默认粘贴行为
        event.preventDefault();
        let text = (event.clipboardData || window.clipboardData).getData('text/plain');
        // 去除首尾空格
        text = text.replace(/^\s+|\s+$/g, '');
        // 不使用 document.execCommand('insertText', false, text); 如果在上下文中所插入的 text 已经包含 HTML 标签或是嵌套在一个其他元素中，则可能会出现不符合预期的情况，比如 DIV 标签的插入。
        // 插入处理后的纯文本
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.deleteContents(); // 清除选区内容

          // Create a text node for the text to be inserted
          const textNode = document.createTextNode(text);
          range.insertNode(textNode);

          // 光标移动到文本之后
          range.setStartAfter(textNode);
          //将 Range 收缩到其开始或结束的位置
          range.collapse(true);
          // 清除当前选定的范围
          selection.removeAllRanges();
          // 添加新的范围
          selection.addRange(range);
        }
        this.onContentChange(event);
      },
      // 插入标签
      handleCommand(item) {
        if (!this.savedRange) return;

        let selectedNode = this.savedRange.endContainer;
        let cursorPosition = this.savedRange.endOffset;

        if (selectedNode.nodeType === 1 && cursorPosition > 0) {
          selectedNode = selectedNode.childNodes[cursorPosition - 1];
          if (selectedNode.nodeType === Node.TEXT_NODE && selectedNode.length > 0) {
            cursorPosition = selectedNode.length;
          } else {
            cursorPosition = 0;
          }
        }
        if (!selectedNode) return;

        const span = this.createTagSpan(item);
        if (selectedNode.nodeType === Node.TEXT_NODE) {
          // 创建一个range对象
          const range = document.createRange();
          // 设置range的开始和结束，使其位于指定位置的同一点
          range.setStart(selectedNode, cursorPosition);
          range.setEnd(selectedNode, cursorPosition);
          // 使用DocumentFragment来插入span元素
          const fragment = document.createDocumentFragment();
          fragment.appendChild(span);
          // 插入创建的fragment及其内容至range所在位置
          range.insertNode(fragment);
          // 清理选区
          range.detach(); // 可选，根据浏览器兼容性

          this.calcWordsCount();
          this.updateTagKeyOptions();
        } else if (
          selectedNode.nodeType === Node.ELEMENT_NODE &&
          this.$refs.editableContent.contains(selectedNode)
        ) {
          // 获取目标节点的下一个兄弟节点
          const nextSibling = selectedNode.nextSibling;
          if (nextSibling) {
            this.$refs.editableContent.insertBefore(span, nextSibling);
          } else {
            this.$refs.editableContent.appendChild(span);
          }
          this.calcWordsCount();
          this.updateTagKeyOptions();
        }
      },
      updateTagKeyOptions() {
        this.$refs.editableContent;
        if (!this.$refs.editableContent) return;
        const hasKeys = [];
        for (const ele of this.$refs.editableContent.children) {
          const key = ele.getAttribute('data-value');
          if (key) hasKeys.push(key);
        }

        this.tagOptions = Object.keys(this.replacementPair).filter(item => {
          return !hasKeys.includes(item);
        });
        this.isUpdateTag = false;
      },
      createTagSpan(item) {
        const span = document.createElement('span');
        span.textContent = `${this.replacementPair[item] || item}`;
        span.setAttribute('contenteditable', false);
        span.setAttribute('data-value', item);
        span.classList.add('tag');
        return span;
      },
      getCurrentContent() {
        try {
          const conten = [];
          // // 获取包含所有类型子节点的集合（包括文本节点和元素节点）
          const allNodes = this.$refs.editableContent.childNodes;
          allNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
              conten.push(node.textContent);
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              const v = node.getAttribute('data-value');
              if (v) {
                conten.push(v);
              }
            }
          });
          return conten.join('');
        } catch (ex) {
          console.error('获取当前内容出错:', ex);
        }
      }
    }
  };
</script>
<style lang="less">
  .prompt-editor {
    height: 160px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    &__bd {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.65);
      height: 160px;
      flex: 1;

      .content-box {
        word-break: break-all;
        overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        height: 100%;
        position: relative;

        .content-input {
          font-size: 14px;
          padding: 4px;
          height: 100%;
          box-sizing: border-box;
          border: 1px solid #d9d9d9;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          color: #262626;
          overflow: auto;
          p &:focus,
          &:focus-visible,
          &:focus-within {
            border-color: #409eff;
            outline: none;
          }

          span {
            line-height: 1.5;
          }

          span.tag {
            color: red;
          }
        }

        // 字数
        .content-count {
          color: #909399;
          background: #fff;
          position: absolute;
          font-size: 12px;
          bottom: 5px;
          right: 10px;
          z-index: 9;

          &__current {
            color: #262626;
          }
        }

        .unselectable {
          user-select: none; /* 标准写法 */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          -webkit-user-select: none; /* Safari 和 Chrome */
          // pointer-events: none; /* 禁止鼠标事件，例如点击选择 */
        }

        &__link {
          color: #1e4edc;
          cursor: pointer;
          &:hover {
            color: #1e4edc;
          }
        }
      }
    }

    &__ft {
      width: 100%;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 4px 6px;

      .menu-tags {
        margin-top: 4px;
        font-size: 12px;

        .vh-dropdown-link {
          cursor: pointer;
        }
        .vh-icon-arrow-down {
          font-size: 12px;
        }
      }

      .btn-time {
        &,
        &:hover,
        &:active,
        &:focus {
          color: #262626;
        }

        &.is-disabled {
          color: #bfbfbf;
        }
      }

      .btn-preview {
        float: right;
      }
    }
  }

  .prompt-editor-preview {
    .vh-message-box__message {
      word-wrap: break-word;
    }
  }
</style>
