<template>
  <div class="offical-show" :class="{ 'is-cms': isCms }">
    <pageTitle v-if="!eventWechatOaData" pageTitle="公众号"></pageTitle>
    <div class="content-box">
      <div class="type_raido">
        <vh-radio-group v-model="tabType">
          <vh-radio :label="0">
            <span>
              开屏提示关注
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  <p>
                    1、「开屏提示关注」支持用户自定义上传信息，进行自定义引流，无需将您的公众号进行授权给第三方
                  </p>
                  <p>2、「开屏提示关注」可在浏览器及微信环境内使用</p>
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </span>
          </vh-radio>
          <vh-radio :label="1">
            <span>
              预约引导关注
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  <p>
                    1.完成微信公众号授权后，将引导至自定义授权公众号，未授权将引导至「活动提醒小助手」公众号
                  </p>
                  <p>
                    2.「预约引导关注」仅在微信环境下生效，用户关注公众号后将向用户自动发送活动链接，点击后一键返回活动页
                  </p>
                  <p>3、开启前请确认已开启微信授权（获取用户身份），未开启不生效</p>
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </span>
          </vh-radio>
          <vh-radio :label="2">
            <span>
              报名强制关注
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  <p></p>
                  <p>
                    1.完成微信公众号授权后，将引导至自定义授权公众号，未授权将引导至「活动提醒小助手」公众号
                  </p>
                  <p>
                    2、「报名强制关注」仅在微信环境下生效，用户关注公众号后将向用户自动发送活动链接，点击后一键返回活动页
                  </p>
                  <p>3、开启前请确认已开启微信授权（获取用户身份），未开启不生效</p>
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </span>
          </vh-radio>
        </vh-radio-group>
      </div>

      <!-- 开屏提示关注 -->
      <template v-if="tabType === 0">
        <div v-if="tabType === 0" class="switch__box switch">
          <div class="switch-label">开屏提示关注</div>
          <vh-switch
            class="vh-role-switch"
            v-model="form.status"
            :active-value="0"
            :inactive-value="1"
            active-color="#ff4949"
            inactive-color="#ccc"
            @change="changeOpenStatus"
            :active-text="activeTitle"
          ></vh-switch>
        </div>
        <div class="form-phone" :class="[{ 'is-cms': isCms }]">
          <div class="official-form" :class="form.status ? 'pre--full-mask' : ''">
            <vh-form
              :class="[{ disabled: form.status }]"
              label-width="85px"
              :model="form"
              ref="officialForm"
              :rules="formRules"
            >
              <vh-form-item label="二维码" prop="img">
                <div class="img-box">
                  <UploadCropper
                    size="custom"
                    ref="upCropper"
                    :imageUrl.sync="domain_url"
                    tipSize="300*300px"
                    tipLimit="2M"
                    :cropRatio="1 / 1"
                    :before-upload="beforeUploadHandler"
                    @onUploadComplete="uploadComplete"
                    @onRemoveImage="removeImage"
                  />
                </div>
              </vh-form-item>
              <vh-form-item label="文案设置">
                <vh-switch
                  class="vh-tip-switch"
                  v-model="form.tip_status"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#ff4949"
                  inactive-color="#ccc"
                  :active-text="`${
                    form.tip_status == 1 ? '已开启' : '开启后'
                  }，可自定义弹窗顶部和底部的提示文案`"
                ></vh-switch>
                <div v-if="!!form.tip_status">
                  <vh-input
                    v-model="form.head_tip"
                    placeholder="请设置公众号弹窗顶部展示的文字信息"
                    :maxlength="15"
                    show-word-limit
                  ></vh-input>
                  <vh-input
                    v-model="form.bottom_tip"
                    style="margin-top: 10px"
                    placeholder="请设置公众号弹窗底部展示的文字信息"
                    :maxlength="15"
                    show-word-limit
                  ></vh-input>
                </div>
              </vh-form-item>
              <vh-form-item label="自动弹出" v-if="!isCms">
                <!--{{alertType 0自动弹出；1-手动弹出}}-->
                <div class="switch__box">
                  <vh-switch
                    v-model="form.alert_type"
                    :active-value="0"
                    :inactive-value="1"
                    active-color="#ff4949"
                    inactive-color="#ccc"
                    :active-text="autoUpText"
                    @change="alertTypeChange"
                  ></vh-switch>
                </div>
              </vh-form-item>
              <vh-form-item label="导航设置" v-if="!isCms">
                <div class="switch__box">
                  <vh-switch
                    v-model="form.show_icon"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#ff4949"
                    inactive-color="#ccc"
                    :active-text="showIconText"
                  ></vh-switch>
                </div>
              </vh-form-item>
              <vh-form-item>
                <vh-button
                  :disabled="!!form.status"
                  type="primary"
                  class="length152"
                  round
                  @click="handleSave"
                >
                  保存
                </vh-button>
              </vh-form-item>
            </vh-form>
          </div>

          <!--  预览页面 -->
          <div class="official-preview">
            <!-- 模拟开关 -->
            <div class="zdy--switch">
              <span
                :class="switchType === 'app' ? 'active' : ''"
                @click.prevent.stop="changeSwitch('app')"
              >
                手机预览
              </span>
              <span
                :class="switchType === 'pc' ? 'active' : ''"
                @click.prevent.stop="changeSwitch('pc')"
              >
                PC预览
              </span>
            </div>

            <div class="official-preview-bdevent" v-if="isCms">
              <!-- pc预览 -->
              <div
                v-show="switchType === 'pc'"
                class="official-preview-pc"
                :class="[sourceTypeName]"
              >
                <!-- 二维码容器 -->
                <div class="qrcode-wapper">
                  <div class="qrcode-box">
                    <p class="qrcode-title" v-if="previewHeadTip">{{ previewHeadTip }}</p>
                    <div class="qrcode-img-box">
                      <img :src="domain_url" alt="" v-if="domain_url" />
                      <img
                        src="../../../common/images/sys/default_code.jpg"
                        alt=""
                        v-if="!domain_url"
                      />
                    </div>
                    <p class="qrcode-tip" v-if="previewBottomTip">{{ previewBottomTip }}</p>
                  </div>
                  <p class="qrcode-close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
                </div>
              </div>

              <!-- wap预览 -->
              <div class="official-preview-wap" v-show="switchType === 'app'">
                <div class="official-preview-wap__hd">
                  <span>公众号</span>
                </div>
                <div class="official-preview-wap__bd">
                  <!-- 具体内容区, 这里加的背景图-->
                  <div class="official-preview-wap__content" :class="[sourceTypeName]">
                    <!-- 二维码容器 -->
                    <div class="qrcode-wapper scence_1">
                      <div class="qrcode-box">
                        <p class="qrcode-title" v-if="previewHeadTip">{{ previewHeadTip }}</p>
                        <div class="qrcode-img-box">
                          <img :src="domain_url" alt="" v-if="domain_url" />
                          <img
                            src="../../../common/images/sys/default_code.jpg"
                            alt=""
                            v-if="!domain_url"
                          />
                        </div>
                        <p class="qrcode-tip" v-if="previewBottomTip">
                          {{ previewBottomTip }}
                        </p>
                      </div>
                      <p class="qrcode-close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="official-preview-bd" v-else>
              <!--PC预览,begin-->
              <div class="official-pc" v-show="switchType === 'pc'">
                <!-- status 控制是否阴影 -->
                <div class="v-preview-content">
                  <!-- 公众号 -->
                  <div
                    class="gzh_app_mask_container"
                    :style="{ opacity: form.alert_type > 0 ? 0 : 1 }"
                  >
                    <div class="gzh_pc_mask"></div>
                    <!-- 扫码外层 -->
                    <div class="gzh_img_layout">
                      <p class="pc_qr_title">{{ previewHeadTip }}</p>
                      <div class="gzh_img v-code-preview">
                        <img :src="domain_url" alt="" v-if="domain_url" />
                        <img
                          src="../../../common/images/sys/default_code.jpg"
                          alt=""
                          v-if="!domain_url"
                        />
                      </div>
                      <p class="gzh_txt">{{ previewBottomTip }}</p>
                      <p class="close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
                    </div>
                  </div>
                  <div class="gzh_pc">
                    <img
                      v-if="form.show_icon === 1"
                      class="gzh_bg_default"
                      src="../../../common/images/official/pcshow@2x.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="gzh_bg_default"
                      src="../../../common/images/official/pc2@2x.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!--PC预览,end-->
              <!--手机预览，begin-->
              <div
                :class="['official-app', { 'show-code': form.show_icon === 1 }]"
                v-show="switchType === 'app'"
              >
                <!--              <span class="title">公众号</span>-->
                <!-- 公众号 -->
                <div
                  class="gzh_app_mask_container"
                  :style="{ opacity: form.alert_type > 0 ? 0 : 1 }"
                >
                  <div class="gzh_app_mask"></div>
                  <div class="gzh_app">
                    <p class="mobile_qr_title">{{ previewHeadTip }}</p>
                    <div class="gzh_img_layout">
                      <div class="img-code v-code-preview app-preview">
                        <img :src="domain_url" alt="" v-if="domain_url" />
                        <img src="../../../common/images/sys/default_code.jpg" v-if="!domain_url" />
                      </div>
                      <p class="gzh_txt">{{ previewBottomTip }}</p>
                    </div>
                    <div class="gzh_app_close">
                      <i class="iconfont-v3 saasicon-wrong"></i>
                    </div>
                  </div>
                  <!-- 非默认图，有文字
                <p class="gzh_txt" v-if="domain_url">扫码关注公众号</p> -->
                </div>
              </div>
              <!--手机预览,end-->
            </div>
          </div>
        </div>
      </template>

      <!-- 预约引导关注、报名强制关注 -->
      <WeChatOfficialAccSet
        v-if="tabType === 1 || tabType === 2"
        :type="tabType"
        :webinarId="webinarId"
        :eventWechatOaData="eventWechatOaData"
        @settingChange="settingChange"
        @formChange="wechatOfficialAccSetChange"
        :isChange.sync="isChange"
      ></WeChatOfficialAccSet>
    </div>

    <begin-play
      :webinarId="$route.params.str"
      v-if="!isCms && $route.query.type != 5 && webinarState != 4"
    ></begin-play>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import beginPlay from '@/components/beginBtn';
  import { sessionOrLocal } from '@/utils/utils';
  import WeChatOfficialAccSet from '@/components/WeChatOfficialAccSet/index.vue';
  import to from 'await-to-js';
  import { UploadCropper } from '@vhcl/upload-cropper';
  export default {
    name: 'OfficialAccCard',
    components: {
      PageTitle,
      beginPlay,
      WeChatOfficialAccSet,
      UploadCropper
    },
    props: {
      eventWechatOaData: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        domain_url: '',
        imgShowUrl: '',
        userId: '',
        // status: null,
        // alertType: null,
        switchType: 'app',
        showPoster: false,
        form: {
          img: '',
          url: '',
          show_icon: 0, // 是否展示二维码icon 1:展示 0:不展示
          alert_type: 1, // 0自动弹出 1手动弹出
          status: 1, // 开启状态  0 开启 1 关闭
          head_tip: '',
          bottom_tip: '',
          tip_status: 1 // 自定义文案开关：1 开启自定义  0 关闭，使用默认文案
        },
        formRules: {
          img: [{ required: true, message: '请上传图片', trigger: 'blur' }],
          url: [
            { required: false, message: '请输入跳转链接', trigger: 'blur' },
            // { pattern: /((http|https):\/\/)?[\w\-_]+(\.[\w\-_]+).*?/, message: '请输入正确的标志链接' , trigger: 'blur'}
            {
              pattern: /(http|https):\/\/[\w\-_]+(\.[\w\-_]+).*?/,
              message: '请输入跳转链接',
              trigger: 'blur'
            },
            { maxlength: 200, message: '跳转链接最多可输入200个字符', trigger: 'blur' }
          ]
        },
        tabType: '',
        isChange: false,
        webinarId: this.$route.params.str,
        isInit: true,
        configData: ''
      };
    },
    computed: {
      previewHeadTip() {
        return this.form.tip_status == 1 ? this.form.head_tip : '请关注公众号后预约直播';
      },
      previewBottomTip() {
        return this.form.tip_status == 1 ? this.form.bottom_tip : '使用微信“扫一扫”关注公众号';
      },
      isCms() {
        return this.eventWechatOaData && this.eventWechatOaData.sourceId;
      },
      sourceTypeName() {
        const sourceType = this.getSourceType();
        if (sourceType === 4) {
          return 'page';
        }
        if (sourceType === 5) {
          return 'series';
        }
        return '';
      },
      activeTitle() {
        return this.form.status
          ? '开启后，进入活动页面时展示公众号'
          : '已开启，进入活动页面时展示公众号';
      },
      autoUpText() {
        return this.form.alert_type == 1
          ? '开启后，进入活动页公众号自动展示'
          : '已开启，进入活动页公众号自动展示';
      },
      showIconText() {
        return this.form.show_icon == 1 ? '已开启，在顶部导航栏展示' : '开启后，在顶部导航栏展示';
      }
    },
    watch: {
      domain_url: {
        handler(val) {
          if (val) {
            this.showPoster = true;
          }
        },
        immediate: true
      },
      form: {
        deep: true,
        handler() {
          console.log('form change');
          this.isChange = true;
        }
      },
      tabType: function (val) {
        this.isChange = true;
      }
    },

    mounted() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      // alert(`isCms:${JSON.stringify(this.eventWechatOaData)}`);
      if (this.isCms) {
        this.form.alert_type = 0; // 自动弹窗 cms里面强制只有自动弹出
      } else {
        this.getData();
      }
      // 获取公众号引流配置信息
      this.initWeChatOfficialAccSet();
    },
    methods: {
      // 二维码上传前校验
      beforeUploadHandler(file) {
        console.log(file);
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        console.log(file.type.toLowerCase());
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isType) {
          this.$vhMessage({
            message: `二维码图片只能是 ${typeList.join('、')} 格式!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `二维码图片大小不能超过 2MB!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      // 上传完成
      uploadComplete(cbData) {
        if (cbData) {
          this.domain_url = this.form.img = cbData.domain_url || '';
          if (this.domain_url) {
            if (this.switchType === 'pc') {
              this.resizePcImg(this.domain_url);
            } else {
              this.resizeImg(this.domain_url);
            }
          }
        }
        // 触发验证
        this.$refs.officialForm.validateField('img');
      },
      // 图片删除
      removeImage() {
        this.form.img = '';
        this.domain_url = '';
      },
      getSourceId() {
        return this.eventWechatOaData?.sourceId ? this.eventWechatOaData.sourceId : this.webinarId;
      },
      getSourceType() {
        // source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
        return this.eventWechatOaData?.sourceId ? this.eventWechatOaData.sourceType : 1;
      },
      async initWeChatOfficialAccSet() {
        const params = {
          // source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
          source_type: this.eventWechatOaData ? this.eventWechatOaData.sourceType : 1,
          source_id: this.eventWechatOaData ? this.eventWechatOaData.sourceId : this.webinarId
        };
        const [err, res] = await to(
          this.$fetch('getDivertingConfigWechatOa', params, {
            'Content-Type': 'application/json'
          })
        );
        if (res && res.code === 200) {
          this.tabType = res.data.follow_type;
          this.configData = res.data;

          if (this.isCms) {
            // 开屏提示信息
            this.form.status = res.data.prompt_enable ? 0 : 1; // 开启状态  0 开启 1 关闭
            this.form.img = res.data.prompt_config.custom_qr_code_url || ''; //二维码图片
            this.domain_url = res.data.prompt_config.custom_qr_code_url || '';
            this.form.head_tip = res.data.head_tip;
            this.form.bottom_tip = res.data.bottom_tip;
            this.form.tip_status = res.data.tip_status || 0;

            if (this.domain_url) {
              if (this.switchType === 'pc') {
                this.resizePcImg(this.domain_url);
              } else {
                this.resizeImg(this.domain_url);
              }
            }
            this.$nextTick(() => {
              console.log('getData isChange');
              this.isChange = false;
            });
          }
        }
      },
      // 开屏提示开关： 关闭时直接调用接口生效。开启时需要点击保存才生效。
      async changeOpenStatus(e) {
        // 开启状态  0 开启 1 关闭,  开启时需要点击保存才生效。
        if (this.form.status === 0) return false;
        if (this.isCms) {
          let params = {
            enable: 0,
            source_type: this.getSourceType(), // 配置关联业务实体类型 source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
            source_id: this.getSourceId(), //配置关联业务实体Id
            prompt_enable: this.form.status === 0 ? 1 : 0,
            follow_type: 0,
            head_tip: this.form.head_tip,
            bottom_tip: this.form.bottom_tip,
            tip_status: this.form.tip_status
          };
          const [err, res] = await to(
            this.$fetch('enableDivertingConfigWechatOa', params, {
              'Content-Type': 'application/json'
            })
          );
          if (res?.code == 200) {
            this.$emit('settingChange');
            this.$vhMessage.success(params.prompt_enable === 0 ? '关闭成功' : '开启成功');
            this.isChange = false;
          } else {
            this.$vhMessage.error(res?.msg || (this.form.status > 0 ? '关闭失败' : '开启失败'));
            this.form.status = this.form.status === 0 ? 1 : 0;
          }
        } else {
          let params = {
            webinar_id: this.webinarId,
            status: this.form.status // 是否开屏提示展示公众号：0开启 1关闭
          };
          this.$fetch('setPublicInfo', params)
            .then(res => {
              this.$vhall_paas_port({
                k: this.form.status == 1 ? 100290 : 100289,
                data: {
                  business_uid: this.userId,
                  user_id: '',
                  webinar_id: this.webinarId,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              this.$vhMessage({
                showClose: true,
                message: this.form.status > 0 ? '关闭成功' : '开启成功',
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.isChange = false;
            })
            .catch(res => {
              this.$vhMessage({
                showClose: true,
                message: res.msg || (this.form.status > 0 ? '关闭失败' : '开启失败'),
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        }
      },
      closePoster() {
        this.showPoster = false;
      },
      changeSwitch(type) {
        this.switchType = type;
        if (this.domain_url) {
          if (this.switchType === 'pc') {
            this.resizePcImg(this.domain_url);
          } else {
            this.resizeImg(this.domain_url);
          }
        }
      },
      getData() {
        this.$fetch('getPublicInfo', {
          webinar_id: this.webinarId
        })
          .then(res => {
            if (res && res.code === 200) {
              this.form.img = res.data.img || '';
              this.form.url = res.data.url || '';
              this.form.status = res.data.status || 0;
              this.form.show_icon = res.data.show_icon || 0;
              this.form.alert_type =
                res.data.alert_type === null ||
                res.data.alert_type === undefined ||
                res.data.alert_type === ''
                  ? 1
                  : res.data.alert_type;
              this.domain_url = res.data.img || '';
              this.form.head_tip = res.data.head_tip;
              this.form.bottom_tip = res.data.bottom_tip;
              this.form.tip_status = res.data.tip_status || 0;

              if (this.domain_url) {
                if (this.switchType === 'pc') {
                  this.resizePcImg(this.domain_url);
                } else {
                  this.resizeImg(this.domain_url);
                }
              }
              this.form.status =
                res.data.status === null || res.data.status === undefined || res.data.status === ''
                  ? 1
                  : res.data.status;
              console.log('getPublicInfo form change');
              this.$nextTick(() => {
                console.log('getData isChange');
                this.isChange = false;
              });
            }
          })
          .catch(res => {
            console.error(res);
            this.$vhMessage({
              message: res.msg || '获取基本信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 开屏提示关注保存
      async handleSave() {
        const [error, valid] = await to(this.$refs.officialForm.validate());
        if (error || !valid) return;
        if (this.isCms) {
          const opt = {
            enable: 1,
            follow_type: 0,
            source_id: this.getSourceId(),
            source_type: this.getSourceType(),
            prompt_enable: this.form.status == 0 ? 1 : 0,
            prompt_config: {
              auto_prompt: 1,
              custom_qr_code_url: this.domain_url // 公众号/开屏海报，图片地址 domain_url是全路径
            },
            head_tip: this.form.head_tip,
            bottom_tip: this.form.bottom_tip,
            tip_status: this.form.tip_status
          };
          const [err, res] = await to(
            this.$fetch('setDivertingConfigWechatOa', opt, {
              'Content-Type': 'application/json'
            })
          );
          if (res?.code === 200) {
            this.$emit('settingChange', 1);
            this.$nextTick(() => {
              this.isChange = false;
            });
            this.$vhMessage.success('保存成功');
          } else {
            this.$vhMessage.error(res?.msg || '保存失败');
          }
        } else {
          const params = {
            webinar_id: this.webinarId,
            status: this.form.status, //是否展示公众号/是否展示开屏海报：0开启1关闭
            img: this.form.img ? this.$parseURL(this.form.img).path : '', // 公众号/开屏海报  图片地址
            show_icon: this.form.show_icon,
            alert_type: this.form.alert_type, // 公众号-弹窗方式：0自动弹出 1手动弹出
            head_tip: this.form.head_tip,
            bottom_tip: this.form.bottom_tip,
            tip_status: this.form.tip_status
          };
          this.$fetch('setPublicInfo', params)
            .then(res => {
              this.$nextTick(() => {
                this.isChange = false;
              });
              this.$vhall_paas_port({
                k: params.alert_type == 1 ? 100292 : 100291,
                data: {
                  business_uid: this.userId,
                  user_id: '',
                  webinar_id: this.webinarId,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              this.$vhMessage({
                message: '保存成功',
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.getData();
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || '保存失败',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
          this.$fetch(
            'enableDivertingConfigWechatOa',
            {
              source_type: 1, // 配置关联业务实体类型 source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
              source_id: this.webinarId, //配置关联业务实体Id
              force_enable: this.configData.force_enable, //开启或关闭标识, 0:关闭, 1:开启
              follow_type: 0
            },
            {
              'Content-Type': 'application/json'
            }
          );
        }
      },
      resizeImg(data) {
        let img = new Image();
        img.src = data;
        this.$nextTick(() => {
          let dom = document.querySelector('.poster-img img');
          if (dom) {
            img.onload = () => {
              let w = img.width;
              let h = img.height;
              if (w !== h) {
                if (w > h) {
                  dom.style.width = '100%';
                  dom.style.height = 'auto';
                } else {
                  dom.style.width = 'auto';
                  dom.style.height = 'auto';
                }
              } else {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              }
            };
          }
        });
      },
      resizePcImg(data) {
        let img = new Image();
        img.src = data;
        this.$nextTick(() => {
          let dom = document.querySelector('.pc-poster-wrap .v-poster-preview');
          if (dom) {
            img.onload = () => {
              let w = img.width;
              let h = img.height;
              if (w > h) {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              } else if (w === h) {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              } else {
                dom.style.width = 'auto';
                dom.style.height = 'auto';
              }
            };
          }
        });
      },
      settingChange(args) {
        this.$emit('settingChange', args);
      },
      wechatOfficialAccSetChange(val) {
        console.log('wechatOfficialAccSetChange form change:', val);
        this.isChange = val;
      },
      alertTypeChange() {
        console.log('alertTypeChange form change');
        this.isChange = true;
      }
    },
    beforeRouteLeave(to, from, next) {
      // 离开页面前判断信息是否修改
      if (!this.isChange) {
        console.log('officialCard beforeRouteLeave:', this.isChange);
        next();
        return false;
      }
      this.$vhConfirm('公众号设置修改未保存，是否放弃保存并离开？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'zdy-message-box',
        lockScroll: false,
        roundButton: true,
        cancelButtonClass: 'zdy-confirm-cancel'
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    }
  };
</script>
<style lang="less" scoped>
  .type_raido {
    padding: 0px 56px 20px 0;
    height: 60px;
    line-height: 40px;

    .vh-radio__label {
      span {
        font-weight: 500;
      }
    }
  }
  .switch__box {
    display: inline-block;
    &.switch {
      background-color: #fff;
      .switch-label {
        display: inline-block;
        text-align: right;
        vertical-align: middle;
        font-size: 14px;
        color: #262626;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }

  .saasicon_shangchuan {
    font-size: 36px;
  }
  .offical-show {
    .content-box {
      background-color: #fff;
      padding: 36px 20px 50px 36px;
      .tabs-box {
        margin-bottom: 40px;
      }
      .saasicon_help_m {
        color: #666;
      }
      .saasicon-wrong {
        color: #fff;
      }
    }
    .show-on {
      position: absolute;
      top: 42px;
      left: 200px;
    }
    .form-phone {
      display: flex;
      background: #fff;
      min-height: 768px;
      border-radius: 4px;

      &.is-cms {
        min-height: 584px;
      }

      .official-preview {
        margin-top: -30px;
      }
    }
    .official-form {
      width: 502px;
      margin-right: 60px;
      position: relative;
    }
    /**PC预览*/
    .v-preview-content {
      position: relative;
      img {
        width: 360px;
        height: 198px;
        display: block;
        margin: 24px auto 0;
        border-radius: 4px;
      }
      .pc-poster-wrap {
        position: absolute;
        width: 56px;
        height: 102px;
        top: 50px;
        right: 16px;
        background: transparent;
      }
      .v-poster-preview {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 58px;
        max-height: 102px;
        transform: translate(-50%, -50%);
        margin-top: 0px;
        object-fit: cover;
      }
      .gzh_app_mask_container {
        transition: all 0.15s;
      }
      .gzh_pc_mask {
        position: absolute;
        display: block;
        margin: 0;
        right: 0;
        text-align: center;
        width: 360px;
        height: 198px;
        background: #000000;
        opacity: 0.6;
      }
      .gzh_img_layout {
        position: absolute;
        display: block;
        margin: 0;
        background: #ffffff;
        text-align: center;
        top: 15px;
        right: calc(50% - 85px);
        border-radius: 8px;
        width: 180px;
        height: 144px;
        .pc_qr_title {
          width: 180px;
          height: 28px;
          border-radius: 8px 8px 0px 0px;
          //background: #FB3A32;
          color: #1a1a1a;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          padding-top: 4px;
        }
        .v-code-preview {
          width: 88px;
          height: 88px;
          margin: 4px auto 4px;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            margin: 0 0;
          }
        }
        .gzh_txt {
          font-size: 12px;
          font-weight: 400;
          color: #8c8c8c;
          line-height: 12px;
          text-align: center;
          width: 100%;
        }
        .close-box {
          text-align: center;
          margin: 18px auto 0;
          color: #fff;
          .saasicon-wrong {
            font-weight: bold;
          }
        }
      }
    }
    /**手机预览*/
    .official-app {
      width: 420px;
      height: 690px;
      //margin-top: -24px;
      margin-left: -47px;
      //background-image: url('../../../common/images/official/phone_yl_1@2x.png');
      background-image: url('../../../common/images/official/h5@2x.png');
      background-position: center;
      &.show-code {
        //background-image: url('../../../common/images/official/phone_yl@2x.png');
        background-image: url('../../../common/images/official/h5show@2x.png');
      }
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      .title {
        display: inline-block;
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        background: #f7f7f7;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
      .gzh_app_mask_container {
        transition: all 0.15s;
      }
      .gzh_app_mask {
        position: absolute;
        display: block;
        margin: 0;
        right: calc(50% - 155px);
        right: calc(50% - 165px);
        top: 85px;
        top: 68px;
        text-align: center;
        width: 310px;
        height: 566px;
        width: 329px;
        height: 596px;
        background: #000000;
        border-radius: 0px 0px 26px 26px;
        opacity: 0.6;
      }

      .gzh_app {
        position: absolute;
        display: block;
        margin: 0;
        text-align: center;
        top: calc(50% - 81px);
        background: transparent;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 200px;
        border-radius: 8px;

        .mobile_qr_title {
          display: block;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          border-radius: 8px 8px 0 0;
          font-weight: 500;
          background-color: #fff;
          padding-top: 16px;
          padding-bottom: 12px;
        }
        .gzh_img_layout {
          background-color: #fff;
          border-radius: 0 0 8px 8px;
          text-align: center;
          padding-bottom: 20px;
        }

        .app-preview {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .gzh_txt {
          font-size: 12px;
          font-weight: 400;
          color: #8c8c8c;
          line-height: 12px;
          text-align: center;
          padding-top: 8px;
        }

        .gzh_app_close {
          display: block;
          width: 24px;
          height: 24px;
          color: #ffffff;
          margin: 12px auto 0;
          .saasicon-wrong {
            font-weight: bold;
          }
        }
      }
    }
  }

  .offical-show.is-cms {
    .content-box {
      padding: 0;
    }
  }
  .img-box {
    width: 100%;
    min-width: 322px;
    height: 140px;
    ::v-deep .vh-upload--picture-card {
      height: 130px;
    }
  }
  ::v-deep .length152 {
    margin-top: 0px !important;
  }
  .vh-card {
    height: 773px;
  }
  .vh-form {
    margin-top: 24px;
  }
</style>

<style lang="less">
  .offical-show {
    .vh-form {
      position: relative;

      // 表单不可用时的遮罩，不包括保存按钮，保存按钮使用disabled属性控制
      &.disabled {
        &:after {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 46px;
          background-color: rgba(255, 255, 255, 0.5);
          z-index: 9;
        }
      }
    }
  }
  .official-preview-bdevent {
    width: 268px;

    .official-preview-pc {
      position: absolute;
      width: 234px;
      height: 150px;
      background-image: url('../../../common/images/official/pc_page.png');
      background-repeat: no-repeat;
      background-size: 100%;
      &.page {
        background-image: url('../../../common/images/official/pc_page.png');
      }
      &.series {
        background-image: url('../../../common/images/official/pc_series.png');
      }

      // 遮罩
      &:before {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
      }

      // 二维码弹窗效果
      .qrcode-wapper {
        position: absolute;
        display: block;
        margin: 0;
        text-align: center;
        background: transparent;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 180px;
        z-index: 3;

        .qrcode-box {
          display: block;
          margin: 0;
          text-align: center;
          width: 180px;
          padding: 8px 0;
          border-radius: 8px;
          background-color: #fff;
          z-index: 3;

          .qrcode-title {
            display: block;
            border-radius: 8px 8px 0 0;
            color: #262626;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            padding-top: 8px;
            padding-bottom: 4px;
          }
          .qrcode-img-box {
            width: 60.6px;
            height: 60.6px;
            margin: 4px auto 4px;
            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
              margin: 0 0;
            }
          }
          .qrcode-tip {
            font-size: 10px;
            font-weight: 400;
            color: #8c8c8c;
            line-height: 10px;
            text-align: center;
            width: 100%;
          }
        }

        .qrcode-close-box {
          display: block;
          color: #fff;
          margin: 6px auto 0;
          .saasicon-wrong {
            font-weight: bold;
            color: #fff;
          }
        }
      }
    }
    .official-preview-wap {
      width: 214px;
      height: 414px;
      background-color: #f7f7f7;
      -webkit-box-shadow: 0 0 0 10px #f5f5f5;
      box-shadow: 0 0 0 10px #fefefe;
      border-radius: 20px;
      display: flex;
      flex-direction: column;

      &__hd {
        font-size: 12px;
        height: 36px;
        line-height: 36px;
        text-align: center;
      }
      &__bd {
        flex: 1;
        position: relative;
      }
      // 具体内容区
      &__content {
        position: absolute;
        top: 0px;
        left: 5px;
        right: 5px;
        bottom: 4px;
        background-image: url('../../../common/images/official/h5_page.png');
        background-repeat: no-repeat;
        background-size: 100%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        &.page {
          background-image: url('../../../common/images/official/h5_page.png');
        }
        &.series {
          background-image: url('../../../common/images/official/h5_series.png');
        }

        // 遮罩
        &:before {
          position: absolute;
          content: ' ';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.6);
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          z-index: 1;
        }

        // 二维码弹窗效果
        .qrcode-wapper {
          position: absolute;
          display: block;
          margin: 0;
          text-align: center;
          background: transparent;
          width: 180px;
          left: 50%;
          top: 20%;
          transform: translate(-50%, -20%);
          z-index: 3;

          &.scence_1 {
            top: 50%;
            transform: translate(-50%, -50%);
          }

          .qrcode-box {
            display: block;
            margin: 0;
            text-align: center;
            width: 180px;
            padding: 24px 0;
            border-radius: 8px;
            background-color: #fff;
            z-index: 3;

            .qrcode-title {
              color: #262626;
              display: block;
              font-size: 12px;
              text-align: center;
              border-radius: 8px 8px 0 0;
              font-weight: 500;
              padding-bottom: 12px;
            }
            .qrcode-img-box {
              width: 100px;
              height: 100px;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                margin: 0 0;
              }
            }
            .qrcode-tip {
              font-size: 12px;
              font-weight: 400;
              color: #8c8c8c;
              line-height: 12px;
              text-align: center;
              padding-top: 8px;
            }
          }

          .qrcode-close-box {
            display: block;
            color: #fff;
            margin: 12px auto 0;
            .saasicon-wrong {
              font-weight: bold;
              color: #fff;
            }
          }
        }

        // 自动回放效果
        .auto-reply {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #fff;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          padding: 12px;
          z-index: 2;

          .auto-title {
            color: #1a1a1a;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 8px;
            line-height: 14px;
            height: 30px;
          }

          .auto-bottom {
            display: flex;
            width: 180px;

            .auto-intro {
              color: #666;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-right: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              word-break: break-word;
              height: 35px;
              flex: 1;
            }
            .auto-head-img {
              width: 46px;
              height: 37px;
              .head-img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>
