<template>
  <div class="channel-card">
    <div class="title-data">
      <span>推广渠道</span>
      <span class="subTitle" v-if="hasInvitePoster">
        带有渠道参数的「邀约海报」，需提前设置海报内容。
      </span>
      <span class="tool" v-if="hasInvitePoster" @click="toPoster">去设置</span>
    </div>
    <div class="channel-main">
      <div class="search-data">
        <vh-button size="medium" class="length104" type="primary" @click="choseChannel()" round>
          选择渠道
        </vh-button>
        <vh-button
          size="medium"
          class="head-btn length104 transparent-btn"
          round
          plain
          @click="allDelete(null)"
          :disabled="!channel_ids.length"
        >
          批量删除
        </vh-button>
        <vh-dropdown class="length104 fr ml-8" @command="exportChannel">
          <vh-button size="medium" type="primary" round>导出数据</vh-button>
          <vh-dropdown-menu slot="dropdown">
            <vh-dropdown-item class="ellipsis" :command="1">粉丝数据</vh-dropdown-item>
            <vh-dropdown-item class="ellipsis" :command="2">推广渠道</vh-dropdown-item>
          </vh-dropdown-menu>
        </vh-dropdown>
        <vh-input
          round
          class="search-tag"
          placeholder="请输入渠道名称/渠道参数"
          v-model="keyword"
          clearable
          @clear="initQueryUserList"
          @keyup.enter.native="initQueryUserList"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQueryUserList"></i>
        </vh-input>
      </div>
      <div class="channel-card-list">
        <div class="list-table-panel" v-if="tableList.length != 0">
          <!-- 表格与分页 -->
          <vh-table
            ref="tableList"
            :data="tableList || []"
            @selection-change="handleTableCheckbox"
            :header-cell-style="{
              color: '#666',
              height: '56px'
            }"
          >
            <vh-table-column type="selection" :selectable="selectableFuc" width="60" align="left" />
            <vh-table-column
              align="left"
              v-for="(item, index) in baseTableColumn"
              :key="index"
              :width="item.width"
              :label="item.label"
              :show-overflow-tooltip="!item.customTooltip"
            >
              <template slot="header" v-if="item.headerTooltip">
                <span class="pr20">{{ item.label }}&nbsp;</span>
                <vh-tooltip effect="dark" placement="top" v-tooltipMove>
                  <div slot="content" v-html="item.headerTooltip"></div>
                  <i :class="`iconfont-v3 saasicon_help_m gary`"></i>
                </vh-tooltip>
              </template>
              <template slot-scope="scope">
                <span :class="item.key">
                  <template v-if="item.key == 'channel_name'">
                    <span>{{ scope.row[item.key] }}</span>
                    <vh-tag class="tip" size="mini" v-show="scope.row.channel_type == 0">
                      默认
                    </vh-tag>
                  </template>
                  <template v-else-if="item.key == 'updated_at'">
                    <div v-if="scope.row.channel_type != 0">
                      <p class="eps">{{ scope.row.last_operator_nickname }}</p>
                      <p>{{ scope.row[item.key] }}</p>
                    </div>
                  </template>
                  <template v-else>{{ scope.row[item.key] }}</template>
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" align="left" width="220px">
              <template slot-scope="scope">
                <span class="tool" @click="openLink(scope.row)">活动链接</span>
                <span class="tool" v-if="hasInvitePoster" @click="openPoster(scope.row)">
                  邀约海报
                </span>
                <span
                  class="tool"
                  v-show="scope.row.channel_type != 0"
                  @click="deleteChannel(scope.row)"
                >
                  删除
                </span>
              </template>
            </vh-table-column>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="limit"
            :currentPage="pageNumber"
            @current-change="currentChangeHandler"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
        <!-- 无消息内容 -->
        <null-page class="search-no-data" v-else></null-page>
      </div>
    </div>
    <!-- 渠道列表 -->
    <wujieSelectChannel ref="selecteChannel" @selected="eventSelected"></wujieSelectChannel>
    <wujieSelectPoster
      ref="selectePoster"
      :channel_code="curPosterChannelCode"
      :source_id="$route.params.str"
      :posterName="posterName"
      :userPermission="userPermission"
      :businessSiteLink="businessSiteLink"
    ></wujieSelectPoster>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4"
    ></begin-play>

    <VhallDialog
      title="活动链接"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <vh-tabs
        v-model="linkType"
        @tab-click="linkTabClick"
        v-if="userPermission['cms.show_global_embed_link']"
      >
        <vh-tab-pane label="活动链接" name="link"></vh-tab-pane>
        <vh-tab-pane label="全局嵌入链接" name="embedLink"></vh-tab-pane>
      </vh-tabs>
      <div class="liveLink" v-if="linkUrl">
        <vh-input v-model="linkUrl" disabled>
          <template slot="append">
            <div class="tool" @click="doCopy">复制</div>
          </template>
        </vh-input>
        <img :src="`http://aliqr.e.vhall.com/qr.png?t=${encodeURIComponent(linkUrl)}`" alt="" />
        <vh-button type="primary" round class="btn" @click="downloadQr">下载二维码</vh-button>
      </div>
      <div class="liveLink" v-else>
        <noData
          null-type="setting"
          text="未设置‘全局嵌入链接’，设置后可使用嵌入链接预览、分发活动"
          :height="20"
        >
          <router-link target="_blank" :to="{ path: '/cms/embed-setting/link' }">
            <vh-button type="primary" round>去设置</vh-button>
          </router-link>
        </noData>
      </div>
    </VhallDialog>
  </div>
</template>
<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { sessionOrLocal, copy } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  import wujieSelectChannel from '@/components/wujieSelectChannel';
  import wujieSelectPoster from '@/components/wujieSelectPoster';
  import { isEmbed } from '../../../utils/utils';
  import EmbedLinkMixin from '@/views/Cms/embedLinkMixin';
  import { DEP_TYPE_NUM } from '@/common/js/constant';
  import noData from '@/components/NullPage';
  export default {
    mixins: [EmbedLinkMixin],
    data() {
      return {
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        channel_ids: [],
        keyword: '',
        pos: 0,
        pageNumber: 1,
        limit: 20,
        total: 0,
        tableList: [],
        baseTableColumn: [
          {
            label: '名称',
            key: 'channel_name',
            width: 'auto'
          },
          {
            label: '参数',
            key: 'channel_code',
            width: 'auto'
          },
          {
            label: '新增粉丝数',
            key: 'new_fans_count',
            headerTooltip:
              '<p>1.数据更新频率：10分钟</p><p>2.新增粉丝数：新关注公众号的用户数量，数据去重</p><p>3.使用公众号中的预约引导关注或强制报名关注功能，观众成功关<br />注公众号，方可展示对应粉丝数据</p>',
            width: 'auto'
          },
          {
            label: '最后操作',
            key: 'updated_at',
            width: 'auto'
          }
        ],
        linkUrl: '',
        dialogVisible: false,
        curPosterChannelCode: '',
        posterName: '',
        linkType: 'link',
        userPermission: JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage')) || {},
        currentRow: null // 选择的是哪一行
      };
    },
    components: {
      NullPage,
      beginPlay,
      wujieSelectChannel,
      wujieSelectPoster,
      noData
    },
    computed: {
      // 邀约海报
      hasInvitePoster() {
        //  btn_invite 1:邀约海报  0:无权限
        if (JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))['btn_invite'] == '1') {
          return true;
        } else {
          return false;
        }
      },
      embedLink() {
        const queryStr = encodeURIComponent(
          `embedMode=1&dep_id=${this.$route.params.str}&dep_type=${DEP_TYPE_NUM['webinar']}&channel=${this.currentRow.channel_code}`
        );
        return `${this.businessSiteLink}?dep_params=${queryStr}`;
      }
    },
    created() {},
    mounted() {
      this.getWebinarInfo();
      this.initQueryUserList();
      if (this.userPermission['cms.show_global_embed_link']) {
        this.getBusinessDomainConfigList();
      }
    },
    methods: {
      linkTabClick() {
        if (this.linkType === 'link') {
          this.linkUrl = `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.$route.params.str}?channel=${this.currentRow.channel_code}`;
        } else {
          if (this.businessSiteLink) {
            this.linkUrl = this.embedLink;
          } else {
            this.linkUrl = '';
          }
        }
      },
      selectableFuc(row) {
        return row.channel_type != 0;
      },
      eventSelected(selectedActives) {
        if (selectedActives.length != 0) {
          let ids = selectedActives.map(e => {
            return e.id;
          });

          let params = {
            channel_ids: ids.join(','),
            source_id: this.$route.params.str || '',
            source_type: 1 //来源类型，source_id不为空时必填，1=活动 2=落地页 3=专题
          };
          this.$fetch('bindChannel', this.$params(params), {
            'Content-Type': 'application/json'
          }).then(res => {
            this.initQueryUserList();
          });
        }
      },
      async getWebinarInfo() {
        // webinar/info调整-正常的信息展示使用 0
        const result = await this.$fetch('getWebinarInfo', {
          webinar_id: this.$route.params.str,
          is_rehearsal: 0
        });
        if (result.code == 200 && result.data) {
          this.posterName = result.data.subject;
        }
      },
      toPoster() {
        let { href } = this.$router.resolve({ path: '/live/invCard/' + this.$route.params.str });
        window.open(href, '_blank');
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.pageNumber = current;
        this.pos = parseInt((current - 1) * this.limit);
        this.getChannelTableList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.pageNumber = 1;
        this.limit = current;
        this.pos = 0;
        this.getChannelTableList();
      },
      // 复选框操作
      handleTableCheckbox(val) {
        console.log(val);
        this.channel_ids = val.map(item => item.id);
      },
      initQueryUserList() {
        this.pos = 0;
        this.pageNumber = 1;
        this.limit = 20;
        // 表格切换到第一页
        this.$nextTick(() => {
          if (this.$refs.tableList && this.$refs.tableList.pageInfo) {
            this.$refs.tableList.pageInfo.pageNum = 1;
            this.$refs.tableList.pageInfo.pos = 0;
          }
          this.getChannelTableList();
        });
      },
      getChannelTableList(row) {
        if (row) {
          this.pos = row.pos;
          this.pageNumber = row.pageNum;
        }
        this.loading = true;
        let params = {
          keyword: this.keyword,
          pos: this.pos,
          limit: this.limit,
          source_id: this.$route.params.str || '',
          source_type: 1 //来源类型，source_id不为空时必填，1=活动 2=落地页 3=专题
        };
        this.$fetch('getChannelList', this.$params(params), {
          'Content-Type': 'application/json'
        }).then(res => {
          // 有数据但是当前页没有
          if (
            res &&
            res.code === 200 &&
            res.data &&
            res.data.list.length == 0 &&
            res.data.total != 0
          ) {
            this.getChannelTableList({ pos: 0, pageNum: 1 });
            return;
          }
          this.total = res.data.total;
          this.tableList = res.data.list;
          console.log(this.tableList, '????????this.tableList');
        });
      },
      deleteChannel(rows) {
        this.deleteConfirm(rows.id);
      },
      deleteConfirm(id) {
        this.$vhConfirm('删除后，当前渠道将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch(
            'unbindChannel',
            this.$params({
              channel_ids: id,
              source_id: this.$route.params.str,
              source_type: 1
            }),
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.$refs.tableList && this.$refs.tableList.clearSelection();
                this.getChannelTableList();
                this.channel_ids = [];
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      allDelete(id) {
        id = this.channel_ids.join(',');
        this.deleteConfirm(id);
      },
      choseChannel() {
        if (this.$refs.selecteChannel) {
          this.$nextTick(() => {
            this.$refs.selecteChannel && this.$refs.selecteChannel.initComp();
          });
        }
      },
      openPoster(row) {
        this.curPosterChannelCode = row.channel_code;
        // this.linkUrl = `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.$route.params.str}?channel=${row.channel_code}`;
        //打开海报
        if (this.$refs.selectePoster) {
          this.$nextTick(() => {
            this.$refs.selectePoster && this.$refs.selectePoster.openInit();
          });
        }
      },
      doCopy() {
        copy(this.linkUrl);
        this.$vhMessage({
          message: '复制成功',
          showClose: true, // 是否展示关闭按钮
          // duration: 0, // 自动关闭时间，为0永不关闭
          type: 'success', //  提示类型
          customClass: 'zdy-info-box' // 样式处理
        });
      },
      openLink(row) {
        this.dialogVisible = true;
        this.currentRow = row;
        this.linkType = 'link';
        this.linkUrl = `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.$route.params.str}?channel=${row.channel_code}`;
      },
      downloadQr() {
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous');
        image.onload = () => {
          let canvas = document.createElement('canvas');
          // canvas.width = image.width
          // canvas.height = image.height
          canvas.width = 400;
          canvas.height = 400;
          let context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, 400, 400);
          // context.drawImage(image, 0, 0, image.width, image.height)
          let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
          let a = document.createElement('a'); // 生成一个a元素
          let event = new MouseEvent('click'); // 创建一个单击事件
          a.download = `${this.posterName || 'code'}观看端二维码.png`; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };

        image.src = `http://aliqr.e.vhall.com/qr.png?s=10&m=1&t=${encodeURIComponent(
          this.linkUrl
        )}`;
        // window.open(`http://aliqr.e.vhall.com/qr.png?s=10&m=1&t=${this.linkUrl}`, '_blank');
      },
      exportChannel(type) {
        let params = {
          source_id: this.$route.params.str || '',
          source_type: 1 //来源类型，source_id不为空时必填，1=活动 2=落地页 3=专题
        };
        if (type == '2') {
          params.keyword = this.keyword;
        }
        this.$fetch(type == 1 ? 'exportChannelFans' : 'exportChannelLink', this.$params(params), {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage({
                message: `下载申请成功，${
                  this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
                }`,
                showClose: true,
                // duration: 0,
                type: 'success'
                // customClass: 'zdy-info-box'
              });
              this.$EventBus.$emit('saas_vs_download_change');
            }
          })
          .catch(res => {
            this.$vhMessage({
              customClass: 'aaa',
              type: 'error',
              message: '导出失败',
              zIndex: 5000
            });
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .channel-card {
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      span {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        margin-left: 12px;
      }
      .tool {
        color: #3562fa;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
      .button-tip {
        vertical-align: top;
      }
    }
    .channel-main {
      .vh-card__body {
        padding: 32px 24px;
      }
      .search-data {
        margin-bottom: 30px;
      }

      .search-tag {
        float: right;
        width: 220px;
      }

      .channel-card-list {
        background-color: #fff;
        padding: 24px;
        min-height: 676px;
      }
      .list-table-panel {
        min-height: auto;

        .vh-table {
          .vh-table__body .cell {
            .webinar_num,
            .tool {
              color: #3562fa;
              cursor: pointer;
            }
          }
          .vh-table__row .tool {
            margin-right: 16px;
          }
          td:last-child,
          th:last-child {
            padding-right: 24px;
            text-align: right;
          }
          .tip {
            margin-left: 6px;
          }
          .eps {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .liveLink {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tool {
        cursor: pointer;
      }
      img {
        width: 150px;
        margin-top: 12px;
      }
      .btn {
        margin-top: 20px;
      }
    }
  }
  .no-live {
    .btn-list .vh-button {
      margin-right: 0;
    }
  }
</style>
