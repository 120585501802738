<template>
  <div class="photo-question">
    <div class="title-input-container">
      <q-title-input
        :q-placeholder="data.extension.placeholder"
        v-model="data.title"
      />
    </div>
    <div class="options-wrap">
      <vh-row :gutter="data.extension.option_layout === 1 ? 0 : 18">
        <vh-checkbox-group
          v-model="data.correct_answer"
          @change="setCurrentAnswer"
          style="width: 100%"
        >
          <draggable
            @end="moved"
            v-model="data.detail.list"
            :scroll-sensitivity="50"
            :scroll-speed="5"
            :force-fallback="true"
            ghost-class="ghost"
            :class="data.extension.option_layout === 1 ? 'one_col' : 'two_col'"
            :options="{
              handle: '.sort-btn',
              animation: 200,
            }"
          >
            <vh-col
              v-for="(item, index) of data.detail.list"
              :span="data.extension.option_layout === 1 ? 24 : 12"
              :key="item.id || item.kid"
              style="position: relative"
              :class="
                data.extension.option_layout === 1
                  ? 'vh-col-item-24'
                  : index % 2 === 0
                  ? 'vh-col-item-12 vh-col-item-12--1'
                  : 'vh-col-item-12 vh-col-item-12--2'
              "
            >
              <div
                :class="[
                  'option-item',
                  data.extension.img_layout === 1 ? 'column' : 'row',
                ]"
              >
                <div
                  class="sort-btn"
                  :class="data.extension.has_img ? 'has-image' : ''"
                >
                  <!--                  <img width="40" src="https://cnstatic01.e.vhall.com/static/images/signup-form/drop.png?v=o78iE3bYNdTd377uDRu6Jw%3D%3D" alt="" style="cursor: move;display: inline-block;padding-top: 5px;" height="40">-->
                  <img
                    width="8"
                    src="https://cnstatic01.e.vhall.com/upload/interacts/screen-imgs/202404/4e/00/4e0048314a03b356b433a19a7e41584a9065.png"
                    alt=""
                    style="cursor: move"
                  />
                </div>
                <i
                  class="del-btn vh-icon-remove-outline"
                  :class="{has_img: data.extension.has_img}"
                  v-show="data.detail.list.length > 2"
                  @click="delOption(index)"
                />
                <upload
                  v-if="data.extension.has_img"
                  :imgUrl="item.imgUrl"
                  @uploadSuccess="
                    (url, mode) => {
                      item.imgUrl = url;
                    }
                  "
                  class="img-upload img-item"
                >
                  <p slot="tip">
                    建议尺寸：*px，小于4M<br />
                    支持jpg、gif、png、bmp
                  </p>
                </upload>
                <div class="option-item-text">
                  <vh-checkbox :label="item.key" class="selector">
                    <i></i>
                  </vh-checkbox>
                  <div class="input-wrap">
                    <q-option-input
                      placeholder="请输入内容"
                      v-model="item.value"
                      maxlength="100"
                      :idx="index"
                    />
                  </div>
                </div>
              </div>
            </vh-col>
          </draggable>
        </vh-checkbox-group>
      </vh-row>
    </div>
  </div>
</template>

<script>
import qTitleInput from "@/vue/components/common/qTitleInput.vue";
import qOptionInput from "@/vue/components/common/qOptionInput.vue";
import Upload from "@/vue/components/common/upload.vue";
import draggable from "vuedraggable";
import optionList from "@/common/enum/options";
export default {
  name: "q-checkbox",
  components: {
    draggable,
    Upload,
    qTitleInput,
    qOptionInput,
  },
  props: {
    data: {},
  },
  // computed: {
  //   layoutList() {
  //     const result = [];
  //     const list = this.data.detail.list;
  //     const num = this.data.extension.option_layout === 1 ? 1 : 2;
  //     list.map((item) => {
  //       if (result.length === 0) {
  //         result.push([item]);
  //       } else {
  //         if (result[result.length - 1].length === num) {
  //           result.push([]);
  //         }
  //         result[result.length - 1].push(item);
  //       }
  //     });
  //     return result;
  //   },
  // },
  methods: {
    addOption() {
      this.data.addOption();
    },
    // noop() {},
    delOption(idx) {
      this.data.delOption(idx);
    },
    setCurrentAnswer(val) {
      this.data.setCurrentAnswer(val);
    },
    // calcSerial(rowIdx, colIdx) {
    //   const num = this.data.extension.option_layout === 1 ? 1 : 2;
    //   return rowIdx * num + colIdx;
    // },
    moved(e) {
      // 更新 key
      for (let i = 0, len = this.data.detail.list.length; i < len; i++) {
        this.$set(this.data.detail.list[i], "key", optionList[i]);
      }

      // 更新当前答案
      const currentDragItem = this.data.detail.list[e.oldIndex];
      const currentItemAfterDrag = this.data.detail.list[e.newIndex];
      if (currentDragItem.is_answer === 1) {
        this.setCurrentAnswer(currentDragItem.key);
      } else if (currentItemAfterDrag.is_answer === 1) {
        this.setCurrentAnswer(currentItemAfterDrag.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-input-container {
  position: relative;
  margin-bottom: 10px;
  &:hover {
    background: #f5f5f5;
    border-radius: 4px;
  }
}
.photo-question {
  .input-wrap {
    display: inline-block;
    width: 386px;
    font-size: 0;
  }
  .option-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid;
    border-color: #f0f0f0;
    margin-bottom: 15px;

    &:hover {
      border-color: #fafafa;
      background: #fafafa;
    }

    // 样式：左图右文
    &.row {
      flex-direction: row;
      justify-content: space-between;
      .option-item-text {
        width: 100%;
        display: flex;
      }
    }
    // 样式：上图下文
    &.column {
      flex-direction: column;
      justify-content: flex-start;
      .option-item-text {
        display: flex;
        width: 100%;
      }
    }
  }

  .input-wrap {
    display: inline-block;
    width: calc(100% - 25px);
    font-size: 0;
    line-height: 22px;
  }
  .img-item {
    //width: 200px;
    height: 112px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .selector {
    width: 24px;
    margin-top: 3px;
    margin-right: 0;
    vertical-align: top;
  }
  .del-btn {
    color: #595959;
    font-size: 14px;
    position: absolute;
    right: -18px;
    padding-left: 5px;
    top: 8px;
    &.has_img {
      padding-left: 0;
      right: -7px;
      top: -7px;
    }
  }

  .options-wrap {
    .vh-col-item-24 {
      padding-left: 40px;
      margin-left: -40px;
      .img-item {
        width: auto;
      }

      .img-upload {
        ::v-deep .drag_upload {
          .vh-upload {
            &.vh-upload--picture-card {
              width: 100%;
              .upload_content {
                width: 200px;
              }
            }
          }
        }
      }
    }
    .vh-col-item-12 {
      .img-item {
        width: 100%;
      }
      &.vh-col-item-12--1 {
        padding-left: 40px !important;
        padding-right: 0px !important;
        margin-left: -30px !important;
      }
      &.vh-col-item-12--2 {
        padding-left: 40px !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
      }

      .img-upload {
        ::v-deep .drag_upload {
          .vh-upload {
            &.vh-upload--picture-card {
              width: 100%;
              .upload_content {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .sort-btn {
      cursor: move;
      position: absolute;
      display: none;
      left: -24px;
      top: -2px;
      height: 40px;
      width: 25px;
      &.has-image {
        top: 0px;
      }
    }

    ::v-deep .vh-col {
      &:hover {
        .sort-btn {
          display: flex;
          align-items: center;

          &.has-image {
            align-items: start;
          }
        }
      }
    }
  }
  .two_col {
    display: flex;
    justify-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
