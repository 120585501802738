<template>
  <vh-dialog
    :visible="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    width="744px"
    title="发送设置"
    class="send-set-dialog"
  >
    <div v-loading="isLoading">
      <div class="sms-dialog-content">
        <vh-form
          class="sms-form"
          ref="smsForm"
          :model="smsForm"
          :rules="smsRules"
          hide-required-asterisk
          label-width="68px"
        >
          <vh-form-item label="发送对象" prop="send_user_type_list" class="send-user-list">
            <div class="group">
              <vh-radio :label="true" :disabled="true" :value="true" class="mr-24">
                预约/报名用户
              </vh-radio>
              <span class="send-user-tip">
                注意：如果开启「报名审核」，仅会向审核通过的用户发送通知
              </span>
            </div>
          </vh-form-item>

          <vh-form-item label="发送通知" prop="notice_content">
            <div class="sms-content">
              <div class="send-notice">
                <div class="send-notice__item send-notice__title">开播前提醒</div>
                <div class="send-notice__item">
                  <div class="send-notice__item-label">直播标题：</div>
                  <div class="send-notice__item-text">
                    {{ title }}
                  </div>
                </div>
                <div class="send-notice__item">
                  <div class="send-notice__item-label">直播时间：</div>
                  <div class="send-notice__item-text">
                    {{ webinarInfo.start_time }}
                  </div>
                </div>
                <div class="send-notice__item">
                  <div class="send-notice__item-label">备注：</div>
                  <div class="send-notice__item-text">
                    {{
                      tmpInfo?.info?.biz_notice_type === 1
                        ? '您已成功预约直播，请准时参加'
                        : '订阅的直播即将开始，点击立即进入>'
                    }}
                  </div>
                </div>
                <div class="send-notice__item show-detail">
                  <div class="send-notice__item-label send-notice__item-text">查看详情</div>
                  <div class="send-notice__item-text">
                    <i class="vh-breadcrumb__separator vh-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <vh-radio-group class="url-type" @change="choseUrl" v-model="smsForm.url_type">
                <vh-radio :label="0">活动链接</vh-radio>
                <vh-radio
                  :label="3"
                  :disabled="!businessSiteLink"
                  v-show="haveBusinessSiteLinkPermission"
                >
                  活动嵌入链接
                </vh-radio>
                <vh-radio :label="1" :disabled="!cmsUrl" v-show="showCms">
                  <vh-link
                    @click="e => openSiteList(e, 'site')"
                    :disabled="!cmsUrl"
                    type="primary"
                    :underline="false"
                    class="cms-url"
                  >
                    站点链接
                  </vh-link>
                </vh-radio>
                <vh-radio
                  :label="4"
                  :disabled="!businessSiteLink"
                  v-show="haveBusinessSiteLinkPermission"
                >
                  <vh-link
                    @click="e => openSiteList(e, 'siteEmbed')"
                    :disabled="!businessSiteLink"
                    type="primary"
                    :underline="false"
                    class="cms-url"
                  >
                    站点嵌入链接
                  </vh-link>
                </vh-radio>
              </vh-radio-group>
            </div>
          </vh-form-item>

          <vh-form-item label="发送时间" v-if="smsForm.biz_notice_type == 1">
            <div class="send-time-text">
              {{ smsForm.sendUserType === 1 ? '预约/报名成功后发送' : '审核通过后发送' }}
            </div>
          </vh-form-item>
          <vh-form-item
            label="发送时间"
            prop="first_playback_switch"
            v-else-if="smsForm.biz_notice_type == 3"
            class="playback-set"
          >
            <vh-checkbox
              v-model="smsForm.first_playback_switch"
              :true-label="1"
              :false-label="0"
              :disabled="smsForm.first_playback_status === 1"
            >
              首次设置默认回放时发送
            </vh-checkbox>
            <span
              class="custom-time-status first-playback-status"
              :class="[smsForm.first_playback_status === 1 ? 'success' : '']"
            >
              {{ smsForm.first_playback_status === 1 ? '已发送' : '未发送' }}
            </span>
          </vh-form-item>
          <vh-form-item
            v-else-if="smsForm.biz_notice_type != 4"
            label="发送时间"
            prop="send_time"
            class="send-time-chose"
          >
            <div class="send-time">
              <vh-radio-group v-model="smsForm.send_time" size="small">
                <vh-radio
                  v-for="item in sendTimeJson"
                  :key="item.value"
                  :label="item.value"
                  border
                  round
                  :disabled="
                    task_list && task_list.length > 0 && [1, 3].includes(getCheckStatus(item))
                  "
                  class="yes-timer"
                  :class="{
                    'old-timer': [1, 3].includes(getCheckStatus(item))
                  }"
                >
                  <span>{{ item.label }}</span>
                  <template
                    v-if="task_list && task_list.length > 0 && smsForm.send_time == item.value"
                  >
                    <!-- 1=已发送，0=未发送（默认），2=发送中 ，3=已过时-->
                    <template v-if="getCheckStatus(item) == 1">
                      <span class="send_time_status">
                        <img src="../images/fill-success.svg" />
                        已发送
                      </span>
                    </template>
                    <template v-else-if="getCheckStatus(item) == 2">
                      <span class="send_time_status">
                        <img src="../images/fill-send.svg" />
                        发送中
                      </span>
                    </template>
                    <template v-else-if="getCheckStatus(item) == 3">
                      <span class="send_time_status">
                        <img src="../images/fill-warning.svg" />
                        已过时
                      </span>
                    </template>
                    <template v-if="getCheckStatus(item) === 0">
                      <span class="send_time_status">
                        <img src="../images/fill-wait.svg" />
                        未发送
                      </span>
                    </template>
                  </template>
                </vh-radio>
              </vh-radio-group>
              <p class="send-time__desc">
                {{
                  `注意：若勾选项距离开播时间不足将无法发送，当前开播时间：${
                    activity_start_time ? activity_start_time : '--'
                  }`
                }}
              </p>
            </div>
          </vh-form-item>
          <vh-form-item
            class="custom-time-chose"
            v-if="![1, 4].includes(smsForm.biz_notice_type)"
            :class="{ 'playback-error': playbackError }"
          >
            <vh-radio v-model="smsForm.send_time" @change="checkCTime" :label="-1">
              自定义时间
            </vh-radio>
          </vh-form-item>
          <template v-if="smsForm.send_time == -1 && smsForm.biz_notice_type != 1">
            <vh-form-item
              v-for="(time, index) in smsForm.customTimes"
              :key="time.key"
              :prop="'customTimes.' + index + '.custom_send_time'"
              :rules="{
                validator: validateTime,
                trigger: 'blur'
              }"
              :label="smsForm.biz_notice_type == 4 && index == 0 ? '发送时间' : ''"
              class="custom-time-list"
            >
              <vh-date-picker
                v-model="time.custom_send_time"
                type="datetime"
                placeholder="选择日期时间"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :default-time="defaultTime"
                prefix-icon
                popper-class="custom-picker"
              ></vh-date-picker>
              <div
                v-show="send_time_tag[time.custom_send_time]"
                :class="[
                  'custom-time-status',
                  time.task_status == 1 ? 'success' : time.task_status == 3 ? 'failed' : ''
                ]"
              >
                <!-- 1=已发送，0=未发送（默认），2=发送中 ，3=已过时 -->
                {{ time.task_status | sendStatus }}
              </div>
            </vh-form-item>
          </template>
        </vh-form>
      </div>
      <div
        class="set-dialog__footer"
        :class="{
          'empty-custom': !smsForm.custom_send_time_switch
        }"
      >
        <p class="set-dialog__footer_left"></p>

        <vh-button type="info" ghost @click="handleClose" size="medium" borderRadius="50">
          取消
        </vh-button>
        <vh-button
          type="primary"
          borderRadius="50"
          @click="saveInfo"
          :disabled="btnDisabled"
          v-preventReClick
          size="medium"
        >
          确定
        </vh-button>
      </div>
    </div>
    <!-- 站点列表弹窗 -->
    <SiteSelect ref="siteSelect" @cancelSelect="cancelSelect" @selected="handleSelectSite" />
  </vh-dialog>
</template>
<script>
  import { cloneDeep, uniq } from 'lodash';
  import SiteSelect from './site-select.vue';
  import EmbedLinkMixin from '@/views/Cms/embedLinkMixin';
  const DEP_TYPE_NUM = {
    webinar: 1, // 直播间
    page: 4, // 单场大会
    series: 5, // 系列会
    website: 6 // 聚合主站
  };
  export default {
    mixins: [EmbedLinkMixin],
    data() {
      const choseTime = '请选择发送时间';
      const validateSendTime = (rule, value, callback) => {
        if (!this.smsForm.send_time) {
          //未开启自定义时间，需要判断是否选择了发送时间
          if (!value.length) {
            this.$vhMessage({
              message: choseTime,
              type: 'warning'
            });
            callback(new Error(choseTime));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      const playBackTime = (rule, value, callback) => {
        if (!this.smsForm.custom_send_time_switch && !value) {
          this.$vhMessage({
            message: choseTime,
            type: 'warning'
          });

          this.playbackError = true;
          callback(new Error(choseTime));
        } else {
          this.playbackError = false;
          callback();
        }
      };
      const sendTimeObj = {
        600: '开播前10分钟',
        1800: '开播前30分钟',
        3600: '开播前1小时',
        7200: '开播前2小时',
        86400: '开播前1天'
      };
      const sendTimeJson = Object.keys(sendTimeObj).map(key => ({
        label: sendTimeObj[key],
        value: +key
      }));
      return {
        dialogVisible: false,
        innerVisible: false,
        vm: null,
        isLoading: false,
        btnDisabled: false, // 是否禁用按钮
        saveLoading: false, // 是否保存执行中
        activity_start_time: '', // 活动开始时间
        task_list: [], // 任务时间列表
        send_time_tag: {}, // 根据当前模版的发送时间生成id和task_status,方便获取id和状态
        smsForm: {
          id: null,
          title: '',
          sms_sign: '',
          biz_notice_type: 5, //预热邀约类型
          notice_switch: 0, // 配置开关：1=开;0=关
          send_time: '', // 发送时间
          first_playback_switch: 1, // biz_notice_type==3 回放才有此数据
          custom_send_time_switch: 0, // 自定义时间check状态
          customTimes: [
            // 自定义时间列表
            {
              custom_send_time: '',
              task_status: 0 // 1=已发送，0=未发送（默认），2=发送中 ，3=已过时
            }
          ],
          sendUserType: 1, // 预约报名模版发送对象是单选值
          url_type: 0,
          site_id: '',
          cmsLinkData: {
            targetLink: '', // cms站点链接
            siteId: '0'
          },
          cmsEmbedLinkData: {
            targetLink: '', // cms站点嵌入链接
            siteId: '0'
          }
        },
        smsRules: {
          send_time: [
            {
              validator: validateSendTime,
              trigger: ['change', 'blur']
            }
          ],
          // 回放类型。发送时间设置
          first_playback_switch: [
            {
              validator: playBackTime,
              trigger: ['change', 'blur']
            }
          ]
        },
        sendTimeJson,
        sendTimeObj,
        cmsUrl: true,
        showCms: this.noticeApp.userPermission['cms'],
        playbackError: false,
        haveBusinessSiteLinkPermission:
          this.noticeApp.userPermission['cms.show_global_embed_link'] == 1, // 全局嵌入链接权限
        openTarget: 'site', // 站点弹窗是站点链接还是嵌入链接打开的
        oldChoseVal: '' // 上一个选择的链接类型
      };
    },
    filters: {
      sendStatus(n) {
        let str = '';
        // 1=已发送，0=未发送（默认），2=发送中 ，3=已过时
        switch (n) {
          case 0:
          case 5:
            str = '未发送';
            break;
          case 1:
            str = '已发送';
            break;
          case 2:
            str = '发送中';
            break;
          case 3:
            str = '已过时';
            break;
          default:
            str = '';
        }
        return str;
      }
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      // 模版信息
      tmpInfo: {
        type: Object,
        require: true
      },
      webinarInfo: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    watch: {
      'smsForm.url_type'(val, oldVal) {
        this.oldChoseVal = oldVal;
      }
    },
    computed: {
      pickerOptions: function () {
        return {
          disabledDate: time => {
            return time.getTime() < Date.now() - 8.64e7;
          }
        };
      },
      defaultTime: function () {
        return dayjs().format('HH:mm:ss');
      },
      title() {
        let title = this.webinarInfo?.subject;
        if (title?.length > 20) {
          title = title.substring(0, 17) + '...';
        }
        return title;
      }
    },
    inject: ['noticeApp'], // 卡片对象
    components: {
      SiteSelect
    },
    methods: {
      cancelSelect() {
        const { url_type, cmsEmbedLinkData, cmsLinkData } = this.smsForm;
        if (
          (url_type === 1 && cmsLinkData.siteId == '0') ||
          (url_type === 4 && cmsEmbedLinkData.siteId == '0')
        ) {
          this.smsForm.url_type = this.oldChoseVal;
        }
      },
      // 活动链接之间的切换
      async choseUrl() {
        const { url_type, cmsEmbedLinkData, cmsLinkData } = this.smsForm;
        if (
          (url_type === 1 && cmsLinkData.siteId == '0') ||
          (url_type === 4 && cmsEmbedLinkData.siteId == '0')
        ) {
          this.openTarget = this.smsForm.url_type === 1 ? 'site' : 'siteEmbed';
          // 首次选择站点链接勾选时打开弹框
          this.openSiteList();
        }
      },
      // 获取短链接
      async joinUrl(targetLink, other = {}) {
        const { url_type, biz_notice_type } = this.smsForm;
        let requestUrl = '';
        let afrStr = `bizNoticeType=${biz_notice_type}&channel=wechat`; // 埋点字符串
        if (url_type === 0) {
          // 通过活动链接获取短链
          const urlPrefix =
            process.env.VUE_APP_ENV === 'test'
              ? 'https://test02-live.vhall.com/'
              : 'https://live.vhall.com/';
          const source_id = this.tmpInfo.webinarId;
          requestUrl = `${urlPrefix}v3/lives/watch/${source_id}?${afrStr}`;
        } else if (url_type === 3) {
          // 活动嵌入链接
          let queryStr = encodeURIComponent(
            `embedMode=1&dep_id=${this.tmpInfo.webinarId}&dep_type=1&${afrStr}`
          );
          requestUrl = `${this.businessSiteLink}?dep_params=${queryStr}`;
        } else if (url_type === 1 || url_type === 4) {
          // 通过站点链或者站点嵌入链接接获取短链

          targetLink =
            url_type === 1
              ? this.smsForm.cmsLinkData.targetLink
              : this.smsForm.cmsEmbedLinkData.targetLink;
          if (url_type == 1) {
            // 首次配置时没有带后缀需要拼接
            if (!targetLink.includes('bizNoticeType')) {
              requestUrl = `${targetLink}?${afrStr}`;
            } else {
              requestUrl = targetLink;
            }
          } else {
            // 优先级：自定义链接>自定义域名>全局嵌入链接>vhall标准域名
            /**
             * B租户有 全局嵌入链接 权限
             * 已配置全局链接
             * 则将请求地址替换为 全局嵌入链接+'?dep_params='
             *
             * http://yapi.vhall.domain/project/1226/interface/api/55906
             * link_type:
             * 【1.3.6】链接类型, 1: 标准链接, 2: 自定义域名链接, 3: 自定义链接, 4: 三方链接，后端已处理好1,2,3的关系，全局需要前端另处理
             * 2 > 3 > 4 > 1
             */
            let depType = DEP_TYPE_NUM[other.type === '2' ? 'series' : 'page'];
            let queryStr = encodeURIComponent(
              `embedMode=1&dep_id=${
                other?.id || this.smsForm.site_id
              }&dep_type=${depType}&${afrStr}`
            );
            requestUrl = `${this.businessSiteLink}?dep_params=${queryStr}`;
          }
        }
        if (requestUrl) {
          this.smsForm.short_url = requestUrl;
        }
        return requestUrl;
      },
      async handleSelectSite(editObj) {
        const { editId, selectedItem } = editObj;
        this.smsForm.url_type = this.openTarget === 'site' ? 1 : 4;
        if (this.smsForm.url_type == 1) {
          // 站点链接
          this.smsForm.cmsLinkData.targetLink = selectedItem.target_link;
          this.smsForm.cmsLinkData.siteId = editId;
        } else {
          // 站点嵌入链接
          this.smsForm.cmsEmbedLinkData.targetLink = selectedItem.target_link;
          this.smsForm.cmsEmbedLinkData.siteId = editId;
        }
        this.smsForm.site_id = editId;
      },
      openSiteList(e, openTarget) {
        e && e.preventDefault();
        // 打开站点列表弹窗
        if (openTarget) {
          this.openTarget = openTarget;
        }
        const { url_type, cmsEmbedLinkData, cmsLinkData } = this.smsForm;
        let siteId = '';
        if (openTarget) {
          // 点击站点链接或者站点嵌入链接文案打开弹窗
          siteId = openTarget === 'site' ? cmsLinkData.siteId : cmsEmbedLinkData.siteId;
        } else {
          // 点击radio首次打开弹窗
          siteId = url_type === 1 ? cmsLinkData.siteId : cmsEmbedLinkData.siteId;
        }

        this.$refs.siteSelect.open({
          editId: siteId
        });
      },
      checkCTime(val) {
        console.log('checkCTime', val);
        if (val) {
          // 移除表单的校验结果 [ 发送时间， 回放首次设置的check ]
          this.$refs.smsForm.clearValidate('send_time');
          this.$refs.smsForm.clearValidate('first_playback_switch');
          this.playbackError = false;
        }
      },
      // 时间校验
      validateTime(rule, value, callback) {
        let arr = this.smsForm.customTimes.map(el => {
          return el.custom_send_time ? new Date(el.custom_send_time).getTime() : null;
        });
        arr = arr.filter(el => {
          return el;
        });
        if (!value) {
          callback(new Error('发送时间不能为空！'));
        } else if (
          arr.filter(el => {
            return el == new Date(value).getTime();
          }).length > 1
        ) {
          this.$vhMessage({
            message: '请设置不同的时间',
            type: 'warning'
          });
          callback(new Error('发送时间不能重复！'));
        } else {
          callback();
        }
      },
      // 添加自定义时间
      addTime() {
        if (this.smsForm.customTimes.length >= 10) {
          this.$vhMessage({
            message: '最多只支持添加10个自定义时间',
            type: 'warning'
          });
          return;
        }

        this.smsForm.customTimes.push({
          custom_send_time: '',
          task_status: -1,
          key: Date.now()
        });
      },
      removeTime(item) {
        var index = this.smsForm.customTimes.indexOf(item);
        if (index !== -1) {
          this.smsForm.customTimes.splice(index, 1);
        }
      },
      hideString(str, len) {
        return str.length > len ? str.substring(0, len) + '...' : str;
      },
      //文案提示问题
      messageInfo(title, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: title,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      // 设置按钮是否禁用
      setBtnDisabled(status) {
        console.log('当前按钮是否禁用btnDisabled', status);
        this.btnDisabled = status;
      },
      getCheckStatus(item) {
        //1=已发送，0=未发送（默认），2=发送中 ，3=已过时
        if (item.value in this.send_time_tag) {
          return this.send_time_tag[item.value]?.task_status;
        }
        return null;
      },
      handleClose() {
        this.dialogVisible = false;
        this.$emit('close');
      },
      resetForm() {
        this.$refs.smsForm.resetFields();
      },
      // 数据保存格式化
      getQuery() {
        let query = cloneDeep(this.smsForm);
        console.log('save----', this.tmpInfo);
        let { webinarId } = this.tmpInfo;
        query.channel_type = 2; //通知渠道类型：1=短信;
        query.source_id = webinarId;
        query.source_type = 1; // 业务数据类型 1:活动
        query.id = this.tmpInfo.info.id; // 业务数据类型 1:活动
        let timeArr = [];
        if (query.send_time == -1) {
          query.custom_send_time_switch = 1;
          query.customTimes.map(el => {
            const id = this.send_time_tag[el.custom_send_time]?.id || 0;
            timeArr.push({
              send_time_type: 2,
              send_time: null,
              custom_send_time: el.custom_send_time,
              id
            });
          });
        } else {
          query.custom_send_time_switch = 0;
          timeArr.push({
            send_time_type: 0,
            send_time: query.send_time,
            custom_send_time: null,
            id: this.send_time_tag[query.send_time]?.id || 0
          });
        }

        query.send_time_list = timeArr;
        if (this.smsForm.biz_notice_type === 1) {
          // query.send_user_type_list = [this.smsForm.sendUserType];
          delete query.send_time_list;
          delete query.custom_send_time_switch; // 预约模版不需要选择时间，后端有校验
        }
        delete query.send_time;
        delete query.customTimes;
        delete query.cmsLinkData;
        delete query.cmsEmbedLinkData;

        console.log('save---', query);
        return query;
      },
      // 保存逻辑
      async saveInfo() {
        this.$refs.smsForm.validate(async valid => {
          if (valid) {
            // 校验check用户组内是否为空
            let res = null;
            if (
              (this.smsForm.url_type === 1 || this.smsForm.url_type === 4) &&
              (!this.smsForm.site_id || this.smsForm.site_id == 0)
            ) {
              return this.messageInfo('请选择站点链接或站点嵌入链接', 'error');
            }
            await this.joinUrl();
            let query = this.getQuery();
            this.btnDisabled = true;
            try {
              /**
               * TODO
               * 预计发送条数的查询及提示
               * 预约报名成功的通知点击保存时不需要有弹窗提示预计发送数，但余额不足，需要提醒
               * 预计发送条数规则：需要根据设置的时间点个数累加计算发送条数，例如短信发送条数为100，设置了3个发送时间点，则预计发送条数为300
               */
              if (this.smsForm.biz_notice_type !== 1) {
                let {
                  send_time_list //发送时间列表
                  // send_user_type_list, //发送对象：1=预约/报名用户;2=白名单;3=导入;4=预约/报名中未观看直播用户;5=观众组，多个以数组形式传递
                } = query;
                console.log('res----短信检测', res);

                const filterSendTimes = send_time_list.filter(item => {
                  // 已过时或者已发送计算发送条数需要过滤掉
                  // item.id为0是新增
                  if (item.id == 0) {
                    return true; //新增不过滤
                  }
                  const time = item.send_time_type == 0 ? item.send_time : item.custom_send_time;
                  const obj = this.send_time_tag[time];
                  if (obj && (obj.task_status == 1 || obj.task_status == 3)) {
                    return false;
                  }
                  return true;
                });

                if (this.smsForm.biz_notice_type === 3) {
                  //回放模版如果未发送需要加上这个时间方便计算发送条数
                  if (
                    this.smsForm.first_playback_switch === 1 &&
                    this.smsForm.first_playback_status === 0
                  ) {
                    filterSendTimes.push({
                      id: 0,
                      send_time: null,
                      send_time_type: 2,
                      custom_send_time: dayjs().format('YYYY-MM-DD HH:mm:ss')
                    });
                  }
                }
                this.saveInfoApi(query);
              } else {
                this.saveInfoApi(query);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            console.log('error submit!!');
            document.querySelector('.sms-dialog-content').scrollTo(0, 0);
            return false;
          }
        });
      },
      // 发送设置 - 接口发送组装
      async saveInfoApi(query) {
        this.saveLoading = true;
        if (this.smsForm.url_type !== 1 && this.smsForm.url_type !== 4) {
          query.site_id = '0';
        }
        try {
          let res = await this.$fetch('noticeTaskTplModify', query, {
            'Content-Type': 'application/json'
          }).catch(res => res);
          if (res.code == 200) {
            this.messageInfo(this.tmpInfo.type === 'create' ? '创建成功' : '修改成功', 'success');
            this.handleClose();
            this.$emit('saveChange');
          } else if (res.code == 8020006) {
            // TODO 返回已发送的时间列表，不允许取消勾选
            this.unCancel(res.data || []);
            const msg = this.getTimeMsg();
            this.messageInfo(msg || '接口调用失败', 'error');
          } else {
            this.messageInfo(res.message || '接口调用失败', 'error');
          }
          this.btnDisabled = false;
        } catch (error) {
          this.saveLoading = false;
          this.btnDisabled = false;
          console.log(error);
        }
      },
      getTimeMsg() {
        const { sTime, cTime } = this.notAllowedList;
        let array = [];
        if (sTime.length) {
          sTime.forEach(key => {
            array.push(this.sendTimeObj[key]);
          });
        }
        return array.concat(cTime).join(',') + '已发送，无法取消勾选';
      },
      // 保存反馈，不允许取消已发送的时间，自动勾选
      unCancel(obj) {
        let sTime = [],
          cTime = []; //已发送成功的时间列表；custom_send_time;task_status=1
        obj.map(el => {
          if (el.send_time_type == 0) {
            sTime.push(el.send_time); // 勾选时间列表
          } else if (el.send_time_type == 2) {
            cTime.push(el.custom_send_time); // 自定义时间列表
          }
        });
        this.notAllowedList = {
          sTime,
          cTime
        };
        if (sTime.length) {
          this.smsForm.send_time = uniq(this.smsForm.send_time.concat(sTime)); // 合并去重，复选已发送的时间
        }
        if (cTime.length) {
          /**
           * TODO  判断cTime 内的时间是否都存在于this.smsForm.customTimes，没有的话添加到customTimes
           */
          cTime.forEach(el => {
            let status = this.smsForm.customTimes.filter(cl => {
              return new Date(el).getTime() == new Date(cl.custom_send_time).getTime();
            });
            if (!status.length) {
              this.smsForm.customTimes.push({
                custom_send_time: el,
                task_status: 1 // 1=已发送，0=未发送（默认），2=发送中 ，3=已过时
              });
            }
          });
        }
      },
      async echoData(data) {
        let info = cloneDeep(data);
        console.log('获取模版详情回显', info);
        this.smsForm.sms_sign = info.sms_sign; // 签名

        // 回显form表单数据
        this.smsForm.short_url = info.short_url;
        this.smsForm.biz_notice_type = info?.biz_notice_type; //info?.biz_notice_type; 1=预约/报名成功通知（默认）;2=开播前提醒;3=回放提醒;4=已开播通知;5=自定义
        this.smsForm.title = info?.title;
        this.smsForm.notice_switch = info?.notice_switch;
        this.smsForm.site_id = info?.site_id;
        this.smsForm.url_type = info?.url_type;
        if (info?.url_type == 1) {
          this.smsForm.cmsLinkData.siteId = info?.site_id;
          this.smsForm.cmsLinkData.targetLink = info?.short_url;
        } else if (info?.url_type == 4) {
          this.smsForm.cmsEmbedLinkData.siteId = info?.site_id;
          this.smsForm.cmsEmbedLinkData.targetLink = info?.short_url;
        }
        this.smsForm.first_playback_status = info?.first_playback_status;
        this.smsForm.targetLink = info?.url_type == 1 ? info?.short_url : '';
        if (this.smsForm.biz_notice_type !== 1) {
        } else {
          this.smsForm.sendUserType = +info?.send_user_type.split(',')[0] || 1;
        }

        /**
         * 回显发送时间及自定义时间
         * send_time_type : 0相对某事件前置发送，1相对某事件后置发送，2自定义时间发送
         *  */
        let time = [],
          cTime = [];
        this.smsForm.first_playback_switch = info?.first_playback_switch; // 首次设置默认回放时发送1=开;0=关
        if (info?.biz_notice_type == 4) {
          this.smsForm.custom_send_time_switch = 1;
          this.smsForm.send_time = -1;
        } else {
          // 自定义时间勾选状态 1=开;0=关
          this.smsForm.custom_send_time_switch = info?.custom_send_time_switch || 0;
        }

        this.task_list = info.task_list || [];

        (info.task_list || []).map(el => {
          const { send_time_type, id, send_time, custom_send_time, task_status } = el;
          if (send_time_type === 0) {
            time.push(el);
            this.send_time_tag[send_time] = {
              id,
              task_status
            };
          } else if (send_time_type === 2) {
            this.smsForm.send_time = -1;
            cTime.push(el);
            this.send_time_tag[custom_send_time] = {
              id,
              task_status
            };
          }
        });
        if (time?.length) {
          this.smsForm.send_time = time.map(el => {
            return el.send_time;
          })[0];
        }
        if (cTime?.length) {
          this.smsForm.customTimes = cTime;
        }
        this.smsForm.id = info?.id;
      },
      // 判断站点链接是否展示和置灰
      checkCmsUrls() {
        let { source_id } = this.tmpInfo?.info;
        let opt = {
          webinar_id: source_id
        };
        this.$fetch('checkWebinarRelPage', opt, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res.code === 200 && res.data) {
              this.cmsUrl = res.data?.is_relation;
            } else {
              console.log(res.msg);
              this.cmsUrl = false;
            }
          })
          .catch(err => {
            console.log('err--1', err);
            this.cmsUrl = false;
            this.messageInfo(err || '接口调用失败', 'error');
          });
      },
      getMsgInfo() {
        this.isLoading = true;
        let { id, source_id, source_type } = this.tmpInfo?.info;
        // 获取回显模版详情数据
        let opt = {
          id,
          source_id,
          source_type
        };
        this.$fetch('noticeTaskTplGetInfo', opt, {
          'Content-Type': 'application/json'
        })
          .then(async res => {
            if (res.code === 200) {
              this.activity_start_time = res.data.activity_start_time; // 活动开始时间

              await this.echoData(res.data);
            } else {
              this.messageInfo(res.msg || '接口调用失败', 'error');
            }
            this.isLoading = false;
            if (this.showCms) {
              this.checkCmsUrls(); // 获取站点链接是否有数据以及是否可以展示
              /**
               * 获取 B租户
               */
              if (this.haveBusinessSiteLinkPermission) {
                this.getBusinessDomainConfigList();
              }
            }
          })
          .catch(err => {
            console.log('err--1', err);
            this.messageInfo(err || '接口调用失败', 'error');
            this.isLoading = false;
          });
      }
    },
    created() {
      this.dialogVisible = this.visible;
      this.getMsgInfo(); // 获取模版详情信息
    }
  };
</script>
<style lang="less">
  .custom-picker {
    .vh-picker-panel__footer .is-plain.vh-picker-panel__link-btn {
      display: none;
    }
  }
</style>
<style lang="less" scoped>
  .send-set-dialog::v-deep .vh-dialog__body {
    padding-right: 10px;
  }
  .send-set-dialog::v-deep .set-dialog__footer {
    padding-right: 22px;
  }
  .sms-dialog-content {
    padding-right: 22px;
    max-height: 480px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .playback-set {
    margin-bottom: 0;
  }
  .sms-form {
    .link-text::v-deep .vh-checkbox__label {
      color: #0a7ff5;
      cursor: pointer;
      &:hover {
        color: #0060cf;
      }
    }
    .send-time-chose {
      margin-bottom: 14px;
    }
  }

  //.color-blue {
  //  color: #1e4edc;
  //}
  //.color-red {
  //  color: #fb2626;
  //}
  // 用户组选择
  .obj-content {
    width: 100%;
    min-height: 88px;
    max-height: 176px;
    padding-right: 19px;
    overflow-y: auto;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  .obj-tag {
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 16px;
    background: #f0f0f0;
  }
  .sms-content {
    //width: 612px;
    //height: 153px;
    .url-type {
      margin-top: -1px;
    }
    .cms-url::v-deep.vh-link {
      vertical-align: baseline;
    }
    &_bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: -3px;
      span {
        margin-right: 8px;
      }
      strong {
        color: #0a7ff5;
      }
    }

    .send-notice {
      padding: 20px 20px 20px 20px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      background-color: #fafafa;
      .send-notice__item {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        &.send-notice__title {
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        &.show-detail {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
        }
        .send-notice__item-label {
          width: 65px;
        }
        .send-notice__item-text {
          color: #262626;
          .vh-icon-arrow-right {
            margin-right: 0;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
  .mr-24 {
    margin-right: 24px;
  }
  .first-playbac-status {
    margin-left: 20px;
  }
  .send-time {
    &::v-deep .vh-radio-group {
      width: 612px;
      .vh-radio {
        width: 196px;
        margin-bottom: 12px;
        margin-left: 0 !important;
        margin-right: 12px !important;
        position: relative;
      }
      .vh-radio:nth-child(3n + 3) {
        margin-right: 0 !important;
      }
      .vh-radio:nth-child(4) {
        margin-bottom: 8px !important;
      }
      .vh-radio:nth-child(5) {
        margin-bottom: 8px !important;
      }
      .vh-radio:nth-child(6) {
        margin-bottom: 8px !important;
      }
      .vh-radio.is-bordered.vh-radio--small {
        padding: 5px 12px;
        line-height: 20px;
        border-radius: 4px;
        // .vh-radio__inner::after {
        //   left: 4px;
        //   top: 1px;
        // }
        &:hover {
          border: 1px solid #ffaaa1;
          .vh-radio__inner {
            border-color: #fb2626;
          }
        }
        &:active {
          background: #fff2f0;
          border: 1px solid #ffaaa1;
          color: #262626;
        }
        &.is-checked {
          background: #fff2f0;
          border: 1px solid #ffaaa1;
          color: #262626;
          &.yes-timer {
            border: 1px solid #d9d9d9;
            background: unset;
          }
        }
        &.old-timer {
          background: #f5f5f5;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.25);
          .vh-radio__input.is-checked .vh-radio__inner,
          .vh-radio__input.is-indeterminate .vh-radio__inner {
            background-color: #bfbfbf;
            border-color: #bfbfbf;
          }
        }
      }
    }
    .send_time_status {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.45);
      position: absolute;
      right: 12px;
      top: 5px;
      img {
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        margin-top: -2px;
      }
    }
    &__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.45);
    }
    &-text {
      line-height: 40px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .custom-time {
    &-chose {
      &.playback-error {
        margin-top: 14px;
      }
      margin-bottom: 14px;
    }
    &-list::v-deep .vh-form-item__content {
      display: flex;
    }
    &-list::v-deep.vh-form-item {
      margin-bottom: 20px;
    }
    &-edit {
      font-size: 18px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
        &.disabled {
          color: rgba(0, 0, 0, 0.15);
        }
      }
    }
    &-status {
      color: rgba(0, 0, 0, 0.85);
      &.success {
        color: #0fba5a;
      }
      &.failed {
        // color: #fb2626;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .set-dialog__footer {
    &.empty-custom {
      margin-top: 2px;
    }
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .set-dialog__footer_left {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #0a7ff5;
      margin-right: auto;
      .set-item__test {
        cursor: pointer;
      }
    }
  }
  /* 测试发送弹出框 */
  .send-test__dialog {
    /deep/.dialog-footer {
      text-align: right;
    }
    .send-test__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.45);
      float: left;
      line-height: 36px;
    }
  }
  /* 余额不足弹出框 */
  .send-no-balance__dialog {
    .tip {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.65);
    }
    //.color-blue {
    //  color: #1e4edc;
    //}
  }

  .send-user-list::v-deep {
    label,
    .group {
      line-height: 27px;
    }
    .send-user-tip {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .first-playback-status {
    margin-left: 20px;
  }
  .custom-time-status {
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.85);
    &.success {
      color: #0fba5a;
    }
    &.failed {
      // color: #fb2626;
      color: rgba(0, 0, 0, 0.25);
    }
  }
</style>
