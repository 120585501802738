<!-- 报名表单提交成功相关设置 -->
<template>
  <div class="submit-setting" :class="{ disabled: disabled }">
    <div class="submit-setting__hd">
      <span class="main-title">提交成功设置</span>
      <vh-tooltip effect="dark" placement="top-start" :popper-options="{ zIndex: 4000 }">
        <div slot="content" style="width: 240px">
          1.仅在活动为预告状态报名成功时显示此弹窗
          <br />
          2.如果在「专题」中设置统一报名表单，并开启用户报名时支持选择专题内活动进行报名时，内容读取专题下的设置
        </div>
        <span class="iconfont-v3 saasicon_help_m"></span>
      </vh-tooltip>
    </div>
    <div class="submit-setting__bd">
      <div class="prompt-block">
        <div class="prompt-item">
          <div class="prompt-item__hd display-sty">展示样式</div>
          <div class="prompt-item__bd">
            <vh-radio-group v-model="subscribe_prompt_type">
              <vh-radio
                :label="1"
                @input.native.prevent="updateValue('subscribe_prompt_type', $event.target.value)"
              >
                文字
              </vh-radio>
              <vh-radio
                :label="2"
                @input.native.prevent="updateValue('subscribe_prompt_type', $event.target.value)"
              >
                图文
              </vh-radio>
            </vh-radio-group>
          </div>
        </div>
      </div>
      <div class="prompt-block type-1" v-show="subscribe_prompt_type == 1">
        <div class="prompt-item">
          <div class="prompt-item__bd">
            <PromptEditor
              ref="thePromptEditor"
              :content="subscribe_prompt_content"
              :replacementPair="replacementPair"
              :previewPair="previewPair"
              @updateContent="updateContent"
            ></PromptEditor>
          </div>
        </div>
      </div>
      <vh-form class="prompt-block type-2" v-show="subscribe_prompt_type == 2">
        <vh-form-item label="图片" required style="margin-bottom: 0">
          <UploadCropper
            class="upload-cropper--prompt"
            size="prompt"
            :imageUrl.sync="subscribe_prompt_img"
            tipSize="320*320px"
            tipLimit="4M"
            :cropRatio="1 / 1"
            :before-upload="beforeUploadHandler"
            @onUploadComplete="uploadComplete"
            @onRemoveImage="removeImage"
          />
        </vh-form-item>
        <vh-form-item label="描述">
          <vh-input
            class="submit-setting__input-desc"
            v-model="subscribe_prompt_desc"
            style="width: 100%"
            type="textarea"
            :maxlength="100"
            autocomplete="off"
            :autosize="{ minRows: 6 }"
            resize="none"
            show-word-limit
            @input.native.prevent="updateValue('subscribe_prompt_desc', $event.target.value)"
          ></vh-input>
        </vh-form-item>
      </vh-form>
    </div>
  </div>
</template>
<script>
  import { UploadCropper } from '@vhcl/upload-cropper';
  import PromptEditor from './PromptEditor.vue';
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    name: 'SubmitSetting',
    components: {
      UploadCropper,
      PromptEditor
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      webinar_id: {
        type: Number | String
      },
      subscribe_prompt_type: {
        type: Number,
        default() {
          return 1;
        }
      },
      subscribe_prompt_content: String,
      subscribe_prompt_img: String,
      subscribe_prompt_desc: String
    },
    data() {
      return {
        webinerInfo: undefined,

        // 编辑时的替换文本:
        // 格式：{ {key}: {showKey} }
        replacementPair: {
          '{start_time}': '{时间}'
        },

        // 预览时的替换值
        // 格式：{ {key}: {value} }
        previewPair: undefined
      };
    },
    mounted() {
      window.a = this;
    },
    watch: {
      webinar_id: {
        handler(val) {
          if (val) {
            // replacement
            this.getWebinerInfo(val);
          }
        },
        immediate: true
      }
    },
    methods: {
      async getWebinerInfo(webinarId) {
        try {
          this.webinerInfo = JSON.parse(sessionOrLocal.get('liveDetailInfo'));
        } catch (e) {
          console.error(e);
        }
        if (!this.webinerInfo) {
          await this.$fetch('getWebinarInfo', { webinar_id: webinarId, is_rehearsal: 0 })
            .then(res => {
              this.webinerInfo = res.data;
            })
            .catch(res => {});
        }
        if (this.webinerInfo) {
          this.previewPair = {
            '{start_time}': this.webinerInfo.start_time
          };
        }
      },
      updateValue(key, newValue) {
        if (key === 'subscribe_prompt_type') {
          newValue = Number(newValue);
        }
        this.$emit(`update:${key}`, newValue);
      },
      updateContent(val) {
        const newValue = (val || '').replace(/^\s+|\s+$/g, '');
        this.$emit('update:subscribe_prompt_content', newValue);
      },
      getSubscribePromptContent() {
        return this.$refs['thePromptEditor'].getCurrentContent();
      },
      beforeUploadHandler(file) {
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isType) {
          this.$vhMessage({
            message: `图片只能是 ${typeList.join('、')} 格式!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `图片大小不能超过 4MB!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      // 上传完成
      uploadComplete(cbData) {
        if (cbData) {
          this.updateValue('subscribe_prompt_img', cbData.domain_url || '');
          // 触发验证
          // this.$refs.officialForm.validateField('auto_reply_config.icon_url');
        }
      },
      removeImage() {
        this.updateValue('subscribe_prompt_img', '');
      }
    }
  };
</script>
<style lang="less" scoped>
  .submit-setting {
    position: relative;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    margin-bottom: 40px;

    &.disabled::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 2;
    }

    &__hd {
      margin-top: 10px;
      .main-title {
        font-weight: bold;
        margin-right: 6px;
      }
    }

    .prompt-block {
      width: 100%;
      padding: 0;

      .prompt-item {
        display: flex;
        margin-top: 10px;

        &__hd {
          width: 40px;

          &.display-sty {
            width: 70px;
          }

          &.has-star::before {
            content: '*';
            color: #fb2626;
          }
        }
        &__bd {
          flex: 1;
        }
      }
    }
  }
</style>
<style lang="less">
  .upload-cropper--prompt {
    width: 238px;
    height: 120px;
    overflow: hidden;

    .std-upload.std-upload--prompt {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .submit-setting__input-desc {
    height: 138px;
    border-radius: 4px;

    .vh-textarea__inner {
      height: 138px !important;
    }
  }
</style>
