<template>
  <div :class="['examination-settings-panel', `${configs.client}`]">
    <div class="tabs-wrap"></div>
    <vh-tabs v-model="activeTab">
      <!-- 全局设置 -->
      <vh-tab-pane label="全局设置" name="whole">
        <div class="tab-content tab-panel">
          <section class="set-item">
            <div class="flex-between">
              <p>参与答题条件</p>
              <vh-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                v-model="qData.answer_condition.type"
                @change="changeAnswerConditionSwitch"
              />
            </div>
            <p class="limit-tip">
              {{
                qData.answer_condition.type == 1 ? "已开启" : "开启后"
              }}，可设置指定观看时长，推送答题后满足累计观看时长的观众可参与答题
            </p>
            <template v-if="qData.answer_condition.type">
              <div class="answer_condition">
                <span>累计观看 </span>
                <vh-select
                  size="mini"
                  v-model="qData.answer_condition.viewing_minutes"
                >
                  <vh-option
                    v-for="n in 16"
                    :key="n"
                    :label="n * 5 + '分钟'"
                    :value="n * 5"
                  >
                  </vh-option>
                </vh-select>
                <span> 可参与答题</span>
              </div>
              <p class="limit-tip">
                提示：观看时长达到指定时间即可参与答题，观看时长定义：同一个用户在直播间的累计时长。暂停观看不计算时长；更换浏览器重新计算时长；同一用户多端观看，各端观看时长独立计算，不累加。需要注意：更换观众身份将重新计算观看时长
              </p>
            </template>
          </section>
          <section class="set-item">
            <div class="flex-between">
              <p>重新答题机会</p>
              <vh-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                @change="changeReAnswer"
                v-model="qData.can_re_answer"
              />
            </div>
            <p class="limit-tip">
              {{
                qData.can_re_answer == 1 ? "已开启" : "开启后"
              }}，当成绩未满分时，可允许重新作答一次
            </p>
          </section>
          <section class="set-item">
            <div class="flex-between">
              <p>强制填写</p>
              <vh-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                :disabled="qData.answer_condition.type == 1"
                v-model="qData.mandatory_filling"
              />
            </div>
            <p class="limit-tip">
              {{
                qData.mandatory_filling == 1 ? "已开启" : "开启后"
              }}，观众未提交答题成功时，将无法关闭答题页面
            </p>
          </section>
          <section class="set-item">
            <div class="flex-between">
              <p>回放填写</p>
              <vh-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                @change="changeReplayFilling"
                v-model="qData.replay_filling"
              />
            </div>
            <p class="limit-tip">
              {{
                qData.replay_filling == 1 ? "已开启" : "开启后"
              }}，直播中已推送的答题，观看回放时可参与答题
            </p>
          </section>
          <section
            class="set-item"
            v-if="!qData.answer_condition.type && !qData.replay_filling"
          >
            <div class="flex-between">
              <p>
                答题时长
                <vh-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom-end"
                  :visible-arrow="false"
                >
                  <div slot="content">
                    1.开启后，观众需在指定时间内完成答题。答题中途离开后倒计时不会停止，建议提醒观众不要长时间离开答题页面<br />2.答题计时，以主办方推送时间进行计时
                  </div>
                  <i class="vh-form-iconfont vh-form-line-question"></i>
                </vh-tooltip>
              </p>
              <vh-switch
                size="mini"
                @change="changeLimitTime"
                v-model="qData.limit_time_switch"
              />
            </div>
            <transition name="fade">
              <div v-if="qData.limit_time_switch">
                <div class="time-limit-settings">
                  <span class="settings-label">设置时长</span>
                  <vh-input-number
                    class="settings-input"
                    :placeholder="qData.limit_time_type == 1 ? '5-300' : '1-60'"
                    v-model="qData.limit_time"
                    :controls="false"
                    size="mini"
                    @focus="oldvalue = qData.limit_time"
                    @blur="!qData.limit_time && (qData.limit_time = oldvalue)"
                    :precision="0"
                    :min="qData.limit_time_type == 1 ? 5 : 1"
                    :max="qData.limit_time_type == 1 ? 300 : 60"
                  ></vh-input-number>
                  <vh-select
                    class="time_type"
                    v-model="qData.limit_time_type"
                    @change="changeTimeType"
                    size="mini"
                  >
                    <vh-option label="分钟" :value="0"></vh-option>
                    <vh-option label="秒" :value="1"></vh-option>
                  </vh-select>
                </div>
                <p class="limit-tip">需在规定时间内完成答题，超时自动收卷</p>
                <div class="rank-switch flex-between">
                  <p>
                    自动推送成绩排行榜
                    <vh-tooltip
                      class="item"
                      effect="dark"
                      placement="bottom-end"
                      :visible-arrow="false"
                    >
                      <div slot="content">
                        开启答题限时后，倒计时结束自动推送成绩排行榜至观看端；关闭后需要人工推送
                      </div>
                      <i class="vh-form-iconfont vh-form-line-question"></i>
                    </vh-tooltip>
                  </p>
                  <vh-switch size="mini" v-model="qData.auto_push_switch" />
                </div>
                <p class="limit-tip">答题时间到期后，自动推送排行榜给观众</p>
              </div>
            </transition>
          </section>
          <section class="set-item">
            <div class="flex-between display_sorce">
              <p>
                显示成绩及答案
                <vh-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom-end"
                  :visible-arrow="false"
                >
                  <div slot="content">
                    默认开启，关闭后观众完成答题不会显示成绩及每道题的答案
                  </div>
                  <i class="vh-form-iconfont vh-form-line-question"></i>
                </vh-tooltip>
              </p>
              <vh-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                :disabled="qData.can_re_answer == 1"
                v-model="qData.display_score"
              />
            </div>
          </section>
        </div>
      </vh-tab-pane>
      <!-- 题目设置 -->
      <vh-tab-pane
        label="题目设置"
        :disabled="qData.detail.length === 0"
        name="single"
      >
        <div v-if="qData.detail[qData.curIndex]" class="tab-panel">
          <div
            class="flex-start layout-settings"
            v-if="qData.detail[qData.curIndex].extension.has_img"
          >
            <p class="settings-label">样式</p>
            <vh-radio-group
              size="mini"
              @change="changeImgLayout"
              v-model="qData.detail[qData.curIndex].extension.img_layout"
            >
              <vh-radio-button :label="1">上图下文</vh-radio-button>
              <vh-radio-button :label="2">左图右文</vh-radio-button>
            </vh-radio-group>
          </div>
          <div
            class="flex-start layout-settings"
            v-if="qData.detail[qData.curIndex].extension.has_img"
          >
            <p class="settings-label">布局</p>
            <vh-radio-group
              size="mini"
              v-model="qData.detail[qData.curIndex].extension.option_layout"
            >
              <vh-radio-button :label="1"> 单列 </vh-radio-button>
              <vh-radio-button
                :label="2"
                v-if="qData.detail[qData.curIndex].extension.img_layout != 2"
              >
                双列
              </vh-radio-button>
            </vh-radio-group>
          </div>
          <div class="score-settings">
            <span class="settings-label">分值</span>
            <vh-input-number
              size="small"
              class="settings-input"
              :controls="false"
              v-model="qData.detail[qData.curIndex].score"
              :precision="0"
              :min="0"
              :max="100"
            ></vh-input-number>
            <span> 分 </span>
          </div>
          <div
            class="answer-container"
            v-if="qData.detail[qData.curIndex].type === 'radio'"
          >
            <p class="settings-label">正确答案 (单选)</p>
            <vh-radio-group
              class="option-wrap"
              @change="setCurrentAnswer"
              v-model="qData.detail[qData.curIndex].correct_answer"
            >
              <vh-radio
                :label="option.key"
                class="option-item"
                v-for="option in qData.detail[qData.curIndex].detail.list"
                :key="option.id || option.kid"
                >{{
                  (option.key + "." + option.value) | overHidden(16)
                }}</vh-radio
              >
            </vh-radio-group>
          </div>
          <div
            class="answer-container"
            v-if="qData.detail[qData.curIndex].type === 'checkbox'"
          >
            <p class="settings-label">正确答案 (多选)</p>
            <vh-checkbox-group
              directionVertical
              v-model="qData.detail[qData.curIndex].correct_answer"
              @change="setCurrentAnswer"
              class="option-wrap"
            >
              <vh-checkbox
                :label="option.key"
                class="option-item"
                v-for="option in qData.detail[qData.curIndex].detail.list"
                :key="option.id"
                >{{
                  (option.key + "." + option.value) | overHidden(16)
                }}</vh-checkbox
              >
            </vh-checkbox-group>
          </div>
        </div>
      </vh-tab-pane>
    </vh-tabs>
  </div>
</template>
<script>
export default {
  name: "ExamEditSettings",
  props: {
    qData: {},
    configs: {
      default() {
        return {
          client: "live",
        };
      },
    },
  },
  filters: {
    overHidden(value = "", len = 0) {
      if (value === null || value === undefined) return "";
      if (value.length > len) {
        return value.substring(0, len) + "...";
      }
      return value;
    },
  },
  watch: {
    "qData.curIndex": function (val) {
      if (val == -1) {
        this.activeTab = "whole";
      } else {
        this.activeTab = "single";
      }
    },
  },
  data() {
    return {
      activeTab: "whole",
      timeLimitSwitch: true,
      rankSwitch: true,
      score: 10,
      radio: "",
      checkbox: [],
      styleSwitch: 1,
      layoutSwitch: 1,
      oldvalue: "",
    };
  },
  methods: {
    changeAnswerConditionSwitch(val) {
      if (!!val) {
        this.qData.limit_time_switch = false;
        this.qData.auto_push_switch = false;
        this.qData.mandatory_filling = 1;
      }
    },
    changeReAnswer(val) {
      if (!!val) {
        this.qData.display_score = 1;
      }
    },
    changeTimeType() {
      const txt = "限时单位已修改，请重新设置限时时间";
      if (this.qData.limit_time_type == 1) {
        if (this.qData.limit_time < 5) {
          this.qData.limit_time = 10;
          this.$vhMessage.warning(txt);
        }
      } else {
        if (this.qData.limit_time > 60) {
          this.qData.limit_time = 10;
          this.$vhMessage.warning(txt);
        }
      }
    },
    changeReplayFilling(val) {
      if (!!val) {
        this.qData.limit_time_switch = false;
      }
    },

    setCurrentAnswer(val) {
      this.qData.detail[this.qData.curIndex].setCurrentAnswer(val);
    },
    changeImgLayout(val) {
      if (val == 2) {
        this.qData.detail[this.qData.curIndex].extension.option_layout = 1;
      }
    },
    changeLimitTime(val) {
      if (val) {
        this.qData.auto_push_switch = true;
      } else {
        this.qData.auto_push_switch = false;
      }
    },
  },
};
</script>

<style lang="scss">
.examination-settings-panel {
  font-size: 14px;
  background: #fff;
  .vh-form-line-question {
    font-size: 12px;
  }
  .vh-tabs {
    height: 100%;
  }
  .vh-tabs__content {
    height: calc(100% - 47px);
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      width: 6px;
      background: #ccc;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .vh-tab-pane {
    height: 100%;
    overflow: auto;
    background: #fff;
  }
  .vh-tabs__header,
  .vh-tabs__header {
    // padding: 4px 20px;
    margin-bottom: 0 !important;
  }
  .tab-panel {
    box-sizing: border-box;
    // height: 348px;
    height: 100%;
    overflow: auto;
  }
  .tab-content {
    padding: 12px 20px;
    color: #262626;
  }
  .flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .flex-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.display_sorce {
      margin-top: 10px;
    }
  }
  .set-item {
    margin-bottom: 8px;
  }
  .answer_condition {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
    color: #333;
    .vh-select {
      width: 80px;
      margin: 0 5px;
    }
  }

  .time-limit-settings {
    margin-top: 12px;
    .time_type {
      width: 64px;
    }
  }
  .settings-label,
  .settings-unit {
    color: #000;
    font-weight: 500;
  }
  .settings-label {
    margin-right: 12px;
  }
  .settings-input {
    width: 70px;
    margin-left: -4px; //对齐
    margin-right: 8px;
    .vh-input__inner {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .limit-tip {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8c8c8c;
  }
  .rank-switch {
    margin-top: 26px;
  }
  // 以下是题目设置
  .score-settings {
    margin: 12px 0;
    padding: 0 20px;
  }
  .answer-container {
    margin-top: 12px;
    background: #fafafa;
    padding: 12px 20px;
    overflow-x: hidden;
  }
  .option-wrap {
    display: block;
    margin-top: 12px;
  }
  .option-item {
    display: block;
    margin-top: 12px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 0 !important;
    // 重写样式
    .vh-radio__label {
      color: #262626;
    }
  }

  .layout-settings {
    padding: 12px 20px;
  }

  // vhall-ui
  .vh-tabs__active-bar {
    background-color: #fb3a32;
  } // tabs
  .vh-tabs--top .vh-tabs__item.is-top:nth-child(2) {
    padding-left: 24px;
  }

  .vh-tabs__nav-wrap {
    overflow: hidden;
    margin-bottom: -1px;
    position: relative;
  }

  .vh-tabs__item {
    padding: 0 24px;
    box-sizing: border-box;
    display: inline-block;
    list-style: none;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 48px;
    height: 48px;
    &.is-active {
      color: #fb2626;
      font-weight: 500;
    }
  }

  .vh-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #fb3a32;
    z-index: 1;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  .vh-form-iconfont {
    color: #8c8c8c;
    font-size: 14px;
  }
}
</style>
