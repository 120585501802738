<template>
  <div class="we-chat-official-acc-set">
    <div class="wechat_auth">
      <span class="wechat_auth-label">发送主体：</span>
      <span class="wechat_auth-con">
        {{ authData?.wechat_oa_name ? authData.wechat_oa_name : '活动提醒小助手' }}
      </span>
      <span class="go-auth" @click="goWechatAuth">
        <template v-if="authData?.wechat_oa_appid">更换公众号</template>
        <template v-else>
          已有公众号？前往授权
          <i class="vh-breadcrumb__separator vh-icon-arrow-right"></i>
        </template>
      </span>
    </div>
    <div class="switch__box switch" style="margin-bottom: 10px">
      <div class="switch-label">{{ type == 1 ? '预约引导关注' : '报名强制关注' }}</div>
      <vh-switch
        class="vh-role-switch"
        v-model="mandatoryAttentionForm.force_enable"
        :active-value="1"
        :inactive-value="0"
        active-color="#ff4949"
        inactive-color="#ccc"
        @change="changeOpenStatus"
        :active-text="type == 1 ? activeTitle1 : activeTitle2"
      ></vh-switch>
    </div>
    <div id="introductionText" style="display: none"></div>
    <div class="form-phone" :class="[{ 'is-cms': isCms }]">
      <div
        class="official-form"
        :class="mandatoryAttentionForm.force_enable === 0 ? 'pre--full-mask' : ''"
      >
        <vh-form
          label-width="94px"
          :model="mandatoryAttentionForm"
          ref="officialForm"
          :rules="mandatoryAttentionFormRules"
          :class="[{ disabled: mandatoryAttentionForm.force_enable === 0 }]"
        >
          <!-- event公众号设置没有导航设置 -->
          <vh-form-item v-if="!eventWechatOaData" label="导航展示" style="margin-bottom: 8px">
            <vh-switch
              class="vh-role-switch"
              v-model="mandatoryAttentionForm.force_config.show_on_navi"
              :active-value="1"
              :inactive-value="0"
              active-color="#ff4949"
              inactive-color="#ccc"
              :active-text="activeTitle3"
            ></vh-switch>
          </vh-form-item>
          <vh-form-item
            label="触发行为"
            prop="force_config.trigger_list"
            :class="`type_${type}`"
            style="margin-bottom: 0"
          >
            <div class="trigger-box">
              <vh-checkbox-group
                text-color="red"
                v-model="mandatoryAttentionForm.force_config.trigger_list"
              >
                <vh-checkbox
                  v-for="(triggerItem, idx) in triggerList"
                  :key="triggerItem.label"
                  :label="triggerItem.label"
                >
                  <vh-tooltip effect="dark" placement="top" v-tooltipMove v-if="idx === 0">
                    <div slot="content">不包含直播中、回放状态</div>
                    <div>{{ triggerItem.text }}</div>
                  </vh-tooltip>
                  <div v-else>
                    {{ triggerItem.text }}
                  </div>
                </vh-checkbox>
              </vh-checkbox-group>
            </div>
          </vh-form-item>
          <vh-form-item label="文案设置">
            <vh-switch
              class="vh-tip-switch"
              v-model="mandatoryAttentionForm.tip_status"
              :active-value="1"
              :inactive-value="0"
              active-color="#ff4949"
              inactive-color="#ccc"
              :active-text="`${
                mandatoryAttentionForm.tip_status == 1 ? '已开启' : '开启后'
              }，可自定义弹窗顶部和底部的提示文案`"
            ></vh-switch>
            <div v-if="mandatoryAttentionForm.tip_status == 1">
              <vh-input
                v-model="mandatoryAttentionForm.head_tip"
                placeholder="请设置公众号弹窗顶部展示的文字信息"
                :maxlength="15"
                show-word-limit
              ></vh-input>
              <vh-input
                v-model="mandatoryAttentionForm.bottom_tip"
                style="margin-top: 10px"
                placeholder="请设置公众号弹窗底部展示的文字信息"
                :maxlength="15"
                show-word-limit
              ></vh-input>
            </div>
          </vh-form-item>
          <vh-form-item label="自动回复" style="margin-bottom: 0"></vh-form-item>
          <vh-form-item label="标题" prop="auto_reply_config.title" class="sub-form-item">
            <vh-input
              v-model="mandatoryAttentionForm.auto_reply_config.title"
              class="textType"
              placeholder="请输入标题"
              :disabled="mandatoryAttentionForm.force_enable === 0"
              autocomplete="off"
              :maxlength="30"
              v-clearEmoij
              show-word-limit
            ></vh-input>
            <div class="tips">提示：为了您的内容获得有效传播，建议文字不超过14个字符</div>
          </vh-form-item>
          <vh-form-item label="简介" prop="auto_reply_config.summary" class="sub-form-item">
            <vh-input
              type="textarea"
              v-model="mandatoryAttentionForm.auto_reply_config.summary"
              placeholder="请输入简介"
              :rows="3"
              :maxlength="summaryMaxLen"
              show-word-limit
            ></vh-input>
            <div class="tips">提示：因手机型号不同简介会显示不完整，建议不超过32个字符</div>
          </vh-form-item>
          <vh-form-item
            label="图片"
            prop="auto_reply_config.icon_url"
            class="sub-form-item"
            label-width="94px"
          >
            <div class="img-box">
              <UploadCropper
                size="custom"
                :imageUrl.sync="mandatoryAttentionForm.auto_reply_config.icon_url"
                tipSize="300*300px"
                tipLimit="2M"
                :cropRatio="1 / 1"
                :before-upload="beforeUploadHandler"
                @onUploadComplete="uploadComplete"
                @onRemoveImage="removeImage"
              />
            </div>
          </vh-form-item>
          <vh-form-item>
            <vh-button
              :disabled="mandatoryAttentionForm.force_enable === 0"
              type="primary"
              class="length152"
              round
              @click="save"
            >
              保存
            </vh-button>
          </vh-form-item>
        </vh-form>
      </div>

      <!--  预览页面 -->
      <div class="official-preview">
        <!-- 模拟开关 -->
        <div class="zdy--switch">
          <span
            :class="previewSwitchType === 'app' ? 'active' : ''"
            @click.prevent.stop="previewSwitchChange('app')"
          >
            手机预览
          </span>
          <span
            :class="previewSwitchType === 'pc' ? 'active' : ''"
            @click.prevent.stop="previewSwitchChange('pc')"
          >
            PC预览
          </span>
        </div>

        <div class="official-preview-bdevent" v-if="isCms">
          <!-- pc预览 -->
          <div
            v-show="previewSwitchType === 'pc'"
            class="official-preview-pc"
            :class="[sourceTypeName]"
          >
            <!-- 二维码容器 -->
            <div class="qrcode-wapper">
              <div class="qrcode-box">
                <p class="qrcode-title">
                  {{ previewHeadTip }}
                </p>
                <div class="qrcode-img-box">
                  <img src="../../common/images/sys/default_code.jpg" alt="" />
                </div>
                <p class="qrcode-tip">
                  {{ previewBottomTip }}
                </p>
              </div>
              <p class="qrcode-close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
            </div>
          </div>

          <!-- wap预览 -->
          <div class="official-preview-wap" v-show="previewSwitchType === 'app'">
            <div class="official-preview-wap__hd">
              <span>公众号</span>
            </div>
            <div class="official-preview-wap__bd">
              <!-- 具体内容区, 这里加的背景图-->
              <div class="official-preview-wap__content" :class="[sourceTypeName]">
                <!-- 二维码容器 -->
                <div class="qrcode-wapper">
                  <div class="qrcode-box">
                    <p class="qrcode-title">
                      {{ previewHeadTip }}
                    </p>
                    <div class="qrcode-img-box">
                      <img src="../../common/images/sys/default_code.jpg" alt="" />
                    </div>
                    <p class="qrcode-tip">
                      {{ previewBottomTip }}
                    </p>
                  </div>
                  <p class="qrcode-close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
                </div>
                <!-- 自动回复示意 -->
                <div class="auto-reply">
                  <!--自动回复示意图-->
                  <div class="auto-title">
                    {{ mandatoryAttentionForm.auto_reply_config.title }}
                  </div>
                  <div class="auto-bottom">
                    <div class="auto-intro">
                      {{ mandatoryAttentionForm.auto_reply_config.summary }}
                    </div>
                    <div class="auto-head-img">
                      <img
                        v-if="mandatoryAttentionForm.auto_reply_config.icon_url"
                        :src="mandatoryAttentionForm.auto_reply_config.icon_url"
                        class="head-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="official-preview-bd" v-else>
          <!--PC预览,begin-->
          <div class="official-pc" v-show="previewSwitchType === 'pc'">
            <!-- status 控制是否阴影 -->
            <div class="v-preview-content">
              <!-- 公众号 -->
              <div class="gzh_app_mask_container">
                <div class="gzh_pc_mask"></div>
                <!-- 扫码外层 -->
                <div class="gzh_img_layout">
                  <p class="pc_qr_title">
                    {{ previewHeadTip }}
                  </p>
                  <div class="gzh_img v-code-preview">
                    <!--                  <img :src="domain_url" alt="" v-if="domain_url" />-->
                    <img src="../../common/images/sys/default_code.jpg" alt="" />
                  </div>
                  <p class="gzh_txt">
                    {{ previewBottomTip }}
                  </p>
                  <p class="close-box"><i class="iconfont-v3 saasicon-wrong"></i></p>
                </div>
              </div>
              <div class="gzh_pc">
                <img
                  v-if="mandatoryAttentionForm.force_config.show_on_navi === 1"
                  class="gzh_bg_default"
                  src="../../common/images/official/pcshow@2x.png"
                  alt=""
                />
                <img
                  v-else
                  class="gzh_bg_default"
                  src="../../common/images/official/pc2@2x.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!--PC预览,end-->
          <!--手机预览，begin-->
          <div
            :class="[
              'official-app',
              { 'show-code': mandatoryAttentionForm.force_config.show_on_navi === 1 }
            ]"
            v-show="previewSwitchType === 'app'"
          >
            <!-- 公众号 -->
            <div class="gzh_app_mask_container">
              <div class="gzh_app_mask"></div>
              <div class="gzh_app">
                <p class="mobile_qr_title">
                  {{ previewHeadTip }}
                </p>
                <div class="gzh_img_layout">
                  <div class="img-code v-code-preview app-preview">
                    <!--                  <img :src="domain_url" alt="" v-if="domain_url" />-->
                    <img src="../../common/images/sys/default_code.jpg" />
                  </div>
                  <p class="gzh_txt">
                    {{ previewBottomTip }}
                  </p>
                </div>
                <div class="gzh_app_close">
                  <i class="iconfont-v3 saasicon-wrong"></i>
                </div>
              </div>
              <div class="auto-box">
                <!--自动回复示意图-->
                <div class="auto-title">
                  {{ mandatoryAttentionForm.auto_reply_config.title }}
                </div>
                <div class="auto-bottom">
                  <div class="auto-intro">
                    {{ mandatoryAttentionForm.auto_reply_config.summary }}
                  </div>
                  <div class="auto-head-img">
                    <img
                      v-if="mandatoryAttentionForm.auto_reply_config.icon_url"
                      :src="mandatoryAttentionForm.auto_reply_config.icon_url"
                      class="head-img"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!-- 非默认图，有文字
            <p class="gzh_txt" v-if="domain_url">扫码关注公众号</p> -->
            </div>
          </div>
          <!--手机预览,end-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { UploadCropper } from '@vhcl/upload-cropper';
  import PageTitle from '../PageTitle/index.vue';
  import to from 'await-to-js';
  export default {
    data() {
      return {
        domain_url: '',
        // alertType: 0,
        previewSwitchType: 'app',
        // userId: JSON.parse(sessionOrLocal.get('userId')),
        mandatoryAttentionForm: {
          source_type: 1, // 配置关联业务实体类型, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
          source_id: this.$route.params.str, // 配置关联业务实体Id
          //   "enable": 1,
          //   "prompt_config": {
          //   "enable": 1,
          //     "trigger_list": [
          //     "webcasting.before_baoming",
          //     "event.before_yuyue"
          //   ],
          //     "custom_qr_code_url": "https://123.com/456.png",
          //     "show_on_navi": 1,
          //     "auto_prompt": 1
          // },
          //   "prompt_enable": 1, // 自动提示 开关
          force_enable: 0, // 预约引导关注/报名强制关注内容，0:未开启，1:开启
          // "auto_reply_enable": 1, //（未使用）自动回复是否开启, 0:未开启，1:开启
          force_config: {
            // "enable": 0,
            trigger_list: [
              'webcasting.webinar_state_2',
              'webcasting.webinar_state_1',
              'webcasting.webinar_state_5'
            ],
            // "custom_qr_code_url": "",
            show_on_navi: 1,
            auto_prompt: 0
          },
          auto_reply_config: {
            enable: 1,
            title: '',
            summary: '',
            icon_url: '',
            target_url: ''
          }
          // mode: 2,
          // trigger: 1,
          // auto_reply_title: '',
          // auto_reply_summary: '点击卡片，进入活动',
          // auto_reply_icon: '',
        },
        mandatoryAttentionFormRules: {
          'auto_reply_config.title': [{ required: true, message: '请输入标题', trigger: 'blur' }],
          'auto_reply_config.summary': [{ required: true, message: '请输入简介', trigger: 'blur' }],
          'auto_reply_config.icon_url': [
            { required: true, message: '请上传手机图片', trigger: 'blur' }
          ],
          'force_config.trigger_list': [
            {
              trigger: 'change',
              validator(rule, value, callback) {
                if (!value || value.length === 0) {
                  callback(new Error('请至少选择一项'));
                } else {
                  callback();
                }
              }
            }
          ]
        },
        triggerList: [
          {
            label: 'webcasting.webinar_state_2', // webinar_state =》 1 直播  2 预告  3 结束 4 点播 5 回放
            text: '开播前预约'
          },
          {
            label: 'webcasting.webinar_state_1', // webinar_state =》 1 直播  2 预告  3 结束 4 点播 5 回放
            text: '观看直播'
          },
          {
            label: 'webcasting.webinar_state_5', // webinar_state =》 1 直播  2 预告  3 结束 4 点播 5 回放
            text: '观看回放'
          }
        ],
        webinarInfo: {},
        summaryMaxLen: 45,
        authData: {}
      };
    },
    props: {
      showTitle: {
        type: Boolean,
        default: false
      },
      pageTitle: {
        type: String,
        default: '公众号配置'
      },
      mask: {
        type: Boolean,
        default: false
      },
      webinarId: {
        type: [Number, String],
        default: ''
      },
      type: {
        default: 1
      },
      isChange: {
        default: false
      },
      eventWechatOaData: {
        type: Object
      }
    },
    computed: {
      previewHeadTip() {
        return this.mandatoryAttentionForm.tip_status == 1
          ? this.mandatoryAttentionForm.head_tip
          : '请关注公众号后预约直播';
      },
      previewBottomTip() {
        return this.mandatoryAttentionForm.tip_status == 1
          ? this.mandatoryAttentionForm.bottom_tip
          : '使用微信“扫一扫”关注公众号';
      },
      isCms() {
        return this.eventWechatOaData && this.eventWechatOaData.sourceId;
      },
      sourceTypeName() {
        const sourceType = this.getSourceType();
        if (sourceType === 4) {
          return 'page';
        }
        if (sourceType === 5) {
          return 'series';
        }
        return '';
      },
      pathUrl: function () {
        return `interacts/screen-imgs`;
      },
      activeTitle1() {
        return `${
          this.mandatoryAttentionForm.force_enable === 0 ? '开启后' : '开启后'
        }，完成「观看限制及报名表单」后推屏显示公众号, 进行引导关注`;
      },
      activeTitle2() {
        return `${
          this.mandatoryAttentionForm.force_enable === 0 ? '开启后' : '开启后'
        }，需要关注公众号成功后才能填写报名表单`;
      },
      activeTitle3() {
        return this.mandatoryAttentionForm.force_config.show_on_navi == 1
          ? '已开启，在顶部导航栏展示'
          : '开启后，在顶部导航栏展示';
      }
    },
    watch: {
      mandatoryAttentionForm: {
        deep: true,
        handler() {
          console.log('mandatoryAttentionForm change');
          this.$emit('update:isChange', true);
        }
      },
      eventWechatOaData: {
        handler(newVal) {
          console.log('newVal newVal:', newVal);
          if (newVal) {
            this.firsInitReplyInfo();
          }
        },
        deep: true,
        immediate: true
      }
    },
    components: {
      PageTitle,
      UploadCropper
    },
    created() {
      // 系列会 和 单场单行 触发行为一致
      if (this.eventWechatOaData?.sourceId) {
        if (this.mandatoryAttentionForm.force_config.trigger_list.length < 4) {
          this.mandatoryAttentionForm.force_config.trigger_list.push('webcasting.webinar_state_3');
        }
        // 系列会 触发行为多1个F
        if (this.triggerList.length < 4) {
          this.triggerList.push({
            label: 'webcasting.webinar_state_3', // webinar_state =》 1 直播  2 预告  3 结束 4 点播 5 回放
            text: '结束后预约'
          });
        }
      }

      this.getMandatoryAttentionData();
      this.getAuthData();
    },
    methods: {
      beforeUploadHandler(file) {
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `二维码图片只能是 ${typeList.join('、')} 格式!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `二维码图片大小不能超过 2MB!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      // 上传完成
      uploadComplete(cbData) {
        if (cbData) {
          this.mandatoryAttentionForm.auto_reply_config.icon_url = cbData.domain_url || '';
          this.domain_url = this.mandatoryAttentionForm.auto_reply_config.icon_url || '';
          // 触发验证
          this.$refs.officialForm.validateField('auto_reply_config.icon_url');
        }
      },
      removeImage() {
        this.mandatoryAttentionForm.auto_reply_config.icon_url = '';
      },
      getSourceId() {
        return this.eventWechatOaData?.sourceId ? this.eventWechatOaData.sourceId : this.webinarId;
      },
      getSourceType() {
        // source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
        return this.eventWechatOaData?.sourceId ? this.eventWechatOaData.sourceType : 1;
      },
      // 首次初始化自动回复的标题、简介、图片等基础信息。只在从未设置公众号配置的情况调用。
      async firsInitReplyInfo() {
        const sourceType = Number(this.getSourceType());
        if ([4, 5, 6].includes(sourceType)) {
          this.mandatoryAttentionForm.source_id = this.eventWechatOaData.sourceId;
          this.mandatoryAttentionForm.source_type = this.eventWechatOaData.sourceType;
          let title = this.eventWechatOaData.title; // 设置标题
          if (title?.length > 30) {
            title = title.substring(0, 27) + '...';
          }
          this.mandatoryAttentionForm.auto_reply_config.title = title; // 设置标题
          this.setSummaryDef(); // 设置简介
          this.mandatoryAttentionForm.auto_reply_config.icon_url = this.eventWechatOaData.cover; // 设置封面
          this.domain_url = this.mandatoryAttentionForm.auto_reply_config.icon_url;

          if (!this.mandatoryAttentionForm.force_config.trigger_list) {
            if (this.eventWechatOaData?.sourceId) {
              // 系列会 和 单场大会 都有4个触发行为
              this.mandatoryAttentionForm.force_config.trigger_list = [
                'webcasting.webinar_state_2',
                'webcasting.webinar_state_1',
                'webcasting.webinar_state_5',
                'webcasting.webinar_state_3'
              ];
            } else {
              this.mandatoryAttentionForm.force_config.trigger_list = [
                'webcasting.webinar_state_2',
                'webcasting.webinar_state_1',
                'webcasting.webinar_state_5'
              ];
            }
          }
          this.$nextTick(() => {
            this.$emit('update:isChange', false);
          });
        } else {
          const [err, res] = await to(
            this.$fetch('getWebinarInfo', { webinar_id: this.webinarId })
          );
          if (res?.code == 200) {
            this.webinarInfo = res?.data || {};
            let title = this.webinarInfo?.subject; // 设置标题
            if (title?.length > 30) {
              title = title.substring(0, 27) + '...';
            }
            this.mandatoryAttentionForm.auto_reply_config.title = title; // 设置标题
            this.setSummaryDef(); // 设置简介
            this.mandatoryAttentionForm.auto_reply_config.icon_url = this.webinarInfo?.img_url; // 设置封面
            this.domain_url = this.mandatoryAttentionForm.auto_reply_config.icon_url;
            this.$nextTick(() => {
              this.$emit('update:isChange', false);
            });
          } else {
            this.$vhMessage.error(res.msg || '获取信息失败');
          }
        }
      },
      setSummaryDef() {
        if (!this.mandatoryAttentionForm.auto_reply_config.summary) {
          const sourceType = Number(this.getSourceType());
          if ([4, 5, 6].includes(sourceType)) {
            this.mandatoryAttentionForm.auto_reply_config.summary = '点击卡片返回活动';
          } else {
            this.mandatoryAttentionForm.auto_reply_config.summary = '点击预约/观看直播';
          }
        }
      },
      // 开关开启/关闭时触发:  关闭时直接调用接口生效。开启时需要点击保存才生效。
      async changeOpenStatus(e) {
        // 开启时需要点击保存才生效。
        if (this.mandatoryAttentionForm.force_enable === 1) return false;

        let params = {
          enable: 0,
          source_type: this.getSourceType(), // 配置关联业务实体类型 source_type, 1:直播间, 3:专题, 4:单场, 5:系列会, 6:聚合站点
          source_id: this.getSourceId(), //配置关联业务实体Id
          force_enable: this.mandatoryAttentionForm.force_enable, //开启或关闭标识, 0:关闭, 1:开启
          follow_type: this.type // 关注类型：0开屏提示关注 1预约引导关注 2报名强制关注
        };
        const [err, res] = await to(
          this.$fetch('enableDivertingConfigWechatOa', params, {
            'Content-Type': 'application/json'
          })
        );
        if (res?.code == 200) {
          this.$emit('settingChange');
          this.$vhMessage.success(params.force_enable === 0 ? '关闭成功' : '开启成功');
          this.$nextTick(() => {
            console.log('changeOpenStatus enableDivertingConfigWechatOa isChange false');
            this.$emit('update:isChange', false);
          });
        } else {
          // 未找到对应的引流配置
          if (res?.code === 8014000) {
            this.firsInitReplyInfo();
          } else {
            this.$vhMessage.success(
              res.msg || (params.force_enable === 0 ? '关闭失败' : '开启失败')
            );
          }
        }
      },
      previewSwitchChange(type) {
        this.previewSwitchType = type;
      },
      // 获取公众号的设置信息
      async getMandatoryAttentionData(isSave) {
        const params = {
          source_type: this.getSourceType(),
          source_id: this.getSourceId()
        };
        const [err, res] = await to(
          this.$fetch('getDivertingConfigWechatOa', params, {
            'Content-Type': 'application/json'
          })
        );
        if (err || !res || res.code != 200) {
          this.$vhMessage.error(res.msg || '获取基本信息失败');
        }
        const data = res.data || {};
        if (
          data.source_id &&
          Array.isArray(data.force_config?.trigger_list) &&
          data.force_config?.trigger_list.length > 0
        ) {
          this.mandatoryAttentionForm = data;
          let title = this.mandatoryAttentionForm?.auto_reply_config?.title; // 设置标题
          if (title?.length > 30) {
            title = title.substring(0, 27) + '...';
          }
          this.mandatoryAttentionForm.auto_reply_config.title = title; // 设置标题
          this.domain_url = this.mandatoryAttentionForm.auto_reply_config.icon_url || '';
          this.setSummaryDef(); // 设置简介

          delete this.mandatoryAttentionForm.prompt_config;
          this.$nextTick(() => {
            isSave && this.$emit('update:isChange', false);
          });
        } else {
          this.firsInitReplyInfo();
        }
      },
      async getAuthData() {
        const [err, res] = await to(
          this.$fetch(
            'getBoundManagedWechatOa',
            {},
            {
              'Content-Type': 'application/json'
            }
          )
        );
        // 根据错误码判断公众号是否已授权
        this.authData = res?.data || {};
      },
      /**
       * 前往：运营设置 > 公众号授权
       */
      goWechatAuth() {
        const { href } = this.$router.resolve({
          path: '/setting/officialAccAuth'
        });
        window.open(href, '_blank');
      },
      save() {
        this.$refs.officialForm.validate(async valid => {
          if (valid) {
            if (!this.authData.wechat_oa_appid) {
              const res = await this.$vhConfirm(
                '当前公众号是「活动提醒小助手」，建议授权自己的公众号进行粉丝引流以及推送开播提醒，您也可以继续使用「活动提醒小助手」',
                '提示',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: '去授权',
                  cancelButtonText: '确定',
                  customClass: 'zdy-message-box',
                  lockScroll: false,
                  roundButton: true,
                  confirmButtonPosition: 'right',
                  cancelButtonClass: 'zdy-confirm-cancel'
                }
              )
                .then(() => {
                  this.goWechatAuth();
                  return false;
                })
                .catch(action => action === 'cancel');
              if (!res) {
                return;
              }
            }

            let opt = {
              ...this.mandatoryAttentionForm,
              source_type: this.getSourceType(),
              source_id: this.getSourceId(),
              follow_type: this.type,
              enable: 1
            };

            delete opt.prompt_enable;
            delete opt.prompt_config;
            delete opt.force_config.custom_qr_code_url;
            this.$fetch('setDivertingConfigWechatOa', opt, {
              'Content-Type': 'application/json'
            })
              .then(res => {
                this.$emit('settingChange', 1);
                this.$vhMessage({
                  message: '保存成功',
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.$emit('update:isChange', false);
                this.getMandatoryAttentionData(true);
              })
              .catch(res => {
                if (res.code === 8014011) {
                  //没有找到托管公众号信息
                  this.$vhConfirm('公众号未授权，授权后可使用强制关注公众号', '提示', {
                    confirmButtonText: '去授权',
                    cancelButtonText: '取消',
                    customClass: 'zdy-message-box',
                    lockScroll: false,
                    roundButton: true,

                    cancelButtonClass: 'zdy-confirm-cancel'
                  })
                    .then(() => {
                      this.$router.push({
                        path: '/setting/officialAccAuth'
                      });
                    })
                    .catch(() => {});
                } else {
                  this.$vhMessage({
                    message: res.msg || '保存失败',
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                }
              });
          }
        });
      },
      resizeImg(data) {
        let img = new Image();
        img.src = data;
        this.$nextTick(() => {
          let dom = document.querySelector('.poster-img img');
          if (dom) {
            img.onload = () => {
              let w = img.width;
              let h = img.height;
              if (w !== h) {
                if (w > h) {
                  dom.style.width = '100%';
                  dom.style.height = 'auto';
                } else {
                  dom.style.width = 'auto';
                  dom.style.height = 'auto';
                }
              } else {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              }
            };
          }
        });
      },
      resizePcImg(data) {
        let img = new Image();
        img.src = data;
        this.$nextTick(() => {
          let dom = document.querySelector('.pc-poster-wrap .v-poster-preview');
          if (dom) {
            img.onload = () => {
              let w = img.width;
              let h = img.height;
              if (w > h) {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              } else if (w === h) {
                dom.style.width = '100%';
                dom.style.height = 'auto';
              } else {
                dom.style.width = 'auto';
                dom.style.height = 'auto';
              }
            };
          }
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      // 离开页面前判断信息是否修改
      if (!this.isChange) {
        next();
        return false;
      }
      this.$vhConfirm('公众号设置修改未保存，是否放弃保存并离开？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'zdy-message-box',
        lockScroll: false,
        roundButton: true,

        cancelButtonClass: 'zdy-confirm-cancel'
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    }
  };
</script>
<style lang="less" scoped>
  .wechat_auth-label {
    color: #595959;
    font-weight: 400;
  }
  .wechat_auth-con {
    color: #262626;
    font-weight: 500;
  }
  .we-chat-official-acc-set {
    .switch__box {
      display: inline-block;
      &.switch {
        background-color: #fff;
        .switch-label {
          display: inline-block;
          text-align: right;
          vertical-align: middle;
          font-size: 14px;
          color: #262626;
          line-height: 40px;
          padding: 0 12px 0 0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
      }
    }
    .form-phone {
      display: flex;
      background: #fff;
      min-height: 768px;
      border-radius: 4px;

      .vh-form {
        min-width: 440px;

        .vh-form-item.type_1 {
          margin-top: 12px;
        }

        .trigger-box {
          width: 400px;
          .vh-checkbox {
            margin-right: 10px;
          }
        }
      }

      &.is-cms {
        min-height: 480px;

        .official-form {
          width: 476px;
          margin-right: 30px;
        }
      }

      .official-preview {
        margin-top: -30px;
      }
      .official-form {
        width: 483px;
        margin-right: 80px;

        .sub-form-item {
          ::v-deep(.vh-form-item__label) {
            font-size: 14px;
          }
        }
        .img-box {
          width: 100%;
          height: 140px;
          ::v-deep .vh-upload--picture-card {
            height: 130px;
          }
        }
        .tips {
          color: rgba(0, 0, 0, 0.45);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-top: 8px;
        }
      }
      /**PC预览*/
      .v-preview-content {
        position: relative;
        img {
          width: 360px;
          height: 198px;
          display: block;
          margin: 24px auto 0;
          border-radius: 4px;
        }
        .pc-poster-wrap {
          position: absolute;
          width: 56px;
          height: 102px;
          top: 50px;
          right: 16px;
          background: transparent;
        }
        .v-poster-preview {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 58px;
          max-height: 102px;
          transform: translate(-50%, -50%);
          margin-top: 0px;
          object-fit: cover;
        }
        .gzh_app_mask_container {
          transition: all 0.15s;
        }
        .gzh_pc_mask {
          position: absolute;
          display: block;
          margin: 0;
          right: 0;
          text-align: center;
          width: 360px;
          height: 198px;
          background: #000000;
          opacity: 0.6;
          .nav-icon {
            color: gray;
            position: absolute;
            right: 47px;
            top: 17px;
            font-size: 12px;
          }
        }
        .gzh_img_layout {
          position: absolute;
          display: block;
          margin: 0;
          background: #ffffff;
          text-align: center;
          top: 15px;
          right: calc(50% - 85px);
          border-radius: 8px;
          width: 180px;
          height: 144px;
          .pc_qr_title {
            width: 180px;
            height: 28px;
            border-radius: 8px 8px 0px 0px;
            //background: #FB3A32;
            color: #1a1a1a;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            padding: 4px;
          }
          .v-code-preview {
            width: 88px;
            height: 88px;
            margin: 4px auto 4px;
            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
              margin: 0 0;
            }
          }
          .gzh_txt {
            font-size: 12px;
            font-weight: 400;
            color: #8c8c8c;
            line-height: 12px;
            text-align: center;
            width: 100%;
          }
          .close-box {
            text-align: center;
            margin: 18px auto 0;
            color: #fff;
            .saasicon-wrong {
              font-weight: bold;
            }
          }
        }
      }
      /**手机预览*/
      .official-app {
        width: 420px;
        height: 690px;
        //margin-top: -24px;
        margin-left: -47px;
        background-image: url('../../common/images/official/h5@2x.png');
        //background-size: 100%;
        background-position: center;
        &.show-code {
          background-image: url('../../common/images/official/h5show@2x.png');
        }
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        .title {
          display: inline-block;
          width: 120px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          background: #f7f7f7;
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
        }
        .poster-img {
          position: absolute;
          height: 520px;
          width: 284px;
          left: 22px;
          top: 78px;
          border-bottom-right-radius: 16px;
          border-bottom-left-radius: 16px;
          overflow: hidden;
          img {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            max-width: 284px;
            max-height: 520px;
          }
        }
        .poster-btn {
          position: absolute;
          right: 20px;
          top: 90px;
        }
        .gzh_app_mask_container {
          transition: all 0.15s;
          .auto-box {
            position: absolute;
            display: block;
            margin: 0;
            right: 0;
            left: 55px;
            left: 45px;
            bottom: 38px;
            bottom: 25px;
            width: 310px;
            width: 331px;
            height: 102px;
            background: #fff;
            border-radius: 0px 0px 26px 26px;
            padding: 12px;
            .auto-title {
              color: #1a1a1a;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 8px;
              line-height: 16px;
              height: 32px;
            }
            .auto-bottom {
              display: flex;
              .auto-intro {
                width: 242px;
                width: 262px;
                color: #666;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-right: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                word-break: break-word;
                height: 36px;
              }
              .auto-head-img {
                width: 38px;
                height: 38px;
                .head-img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .gzh_app_mask {
          position: absolute;
          display: block;
          margin: 0;
          right: calc(50% - 155px);
          right: calc(50% - 165px);
          top: 85px;
          top: 68px;
          text-align: center;
          width: 310px;
          height: 566px;
          width: 329px;
          height: 596px;
          background: #000000;
          border-radius: 0px 0px 26px 26px;
          opacity: 0.6;
        }
        .gzh_app {
          position: absolute;
          display: block;
          margin: 0;
          text-align: center;
          top: calc(50% - 81px);
          background: transparent;
          left: 50%;
          transform: translateX(-50%);
          width: 200px;
          height: 200px;
          border-radius: 8px;

          .mobile_qr_title {
            display: block;
            color: #1a1a1a;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            border-radius: 8px 8px 0 0;
            font-weight: 500;
            background-color: #fff;
            padding-top: 16px;
            padding-bottom: 12px;
          }
          .gzh_img_layout {
            background-color: #fff;
            border-radius: 0 0 8px 8px;
            text-align: center;
            padding-bottom: 20px;
          }

          .app-preview {
            width: 120px;
            height: 120px;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }

          .gzh_txt {
            font-size: 12px;
            font-weight: 400;
            color: #8c8c8c;
            line-height: 12px;
            text-align: center;
            padding-top: 8px;
          }

          .gzh_app_close {
            display: block;
            color: #ffffff;
            margin: 12px auto 0;
            .saasicon-wrong {
              font-weight: bold;
            }
          }
        }
      }
      .official-preview {
        margin-top: -30px;
      }
    }
    .go-auth {
      color: #3562fa;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-left: 12px;
      cursor: pointer;
      .vh-icon-arrow-right {
        color: #3562fa;
        font-size: 12px;
        width: 16px;
        height: 16px;
      }
    }
    .wechat_auth {
      font-size: 14px;
      padding-left: 22px;
      padding-bottom: 10px;
    }
  }
  .vh-role-switch {
    /deep/.vh-switch__label--right {
      max-width: 320px;
      span {
        line-height: 20px;
      }
    }
  }
</style>
