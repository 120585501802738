var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "vmp-exam-info-wap",
          attrs: { "data-is-disabled": _vm.isDisabled },
        },
        _vm._l(_vm.questionList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "vmp-exam-info--question" },
            [
              _c("a-" + item.type, {
                key: item.id,
                ref: `comp_item_${item.id}`,
                refInFor: true,
                tag: "component",
                staticClass: "vmp-exam-item-comp-wap",
                attrs: {
                  item: item,
                  index: item.sortNum,
                  answerType: _vm.answerType,
                  preview: _vm.configs.preview,
                },
                on: { preview: _vm.preview },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("exam-footer", {
        attrs: {
          configs: _vm.configs,
          curPage: _vm.curPage,
          totalPage: _vm.totalPage,
          isDisabled: _vm.isDisabled,
          answerType: _vm.answerType,
          showAnswerAgain: _vm.showAnswerAgain,
        },
        on: {
          toAnswer: function ($event) {
            return _vm.$emit("toAnswer")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }